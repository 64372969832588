import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import Paper from "@mui/material/Paper";
import "./ondate.css";
export default function Wait({redata91,redata92,redata93,redata94,redata95,redata96,redata101,redata102,redata103,redata104,redata105,redata106,redata111,redata112,redata113,redata114,redata115,redata116,redata131,redata132,redata133,redata134,redata135,redata136,redata141,redata142,redata143,redata144,redata145,redata146}) {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  // const URLAlert = "https://lineapi.thaipadi.org";

  const [appdata, setAppdata] = useState([]);
  const [appdatas, setAppdatas] = useState([]);
  const [appdatass, setAppdatass] = useState([]);
  const [appdatasss, setAppdatasss] = useState([]);
  const [appdatassss, setAppdatassss] = useState([]);
  const [appdatasssss, setAppdatasssss] = useState([]);

  const getdata = async () => {
    try {
      const appoinment = [{name:"appoinment",id:'1'},{name:"appoinments",id:'2'},{name:"appoinmentss",id:'3'},{name:"appoinmentsss",id:'4'},{name:"appoinmentssss",id:'5'},{name:"appoinmentsssss",id:'6'}];
      const api = appoinment.map((item) => {
        return `${URL}/${item.name}`;
      });
      const [res1, res2, res3, res4, res5, res6] = await Promise.all([axios.get(api[0]), axios.get(api[1]), axios.get(api[2]), axios.get(api[3]), axios.get(api[4]), axios.get(api[5])]);
      setAppdata(
        res1.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatas(
        res2.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatass(
        res3.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasss(
        res4.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatassss(
        res5.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasssss(
        res6.data.filter((e) => {
          return e.TN > 9;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata()
  }, [URL,redata91,redata92,redata93,redata94,redata95,redata96,redata101,redata102,redata103,redata104,redata105,redata106,redata111,redata112,redata113,redata114,redata115,redata116,redata131,redata132,redata133,redata134,redata135,redata136,redata141,redata142,redata143,redata144,redata145,redata146]);

  const Aalldata = [...appdata, ...appdatas, ...appdatass, ...appdatasss, ...appdatassss, ...appdatasssss];
  const Aalldatal = Aalldata.length;

  return (
        <table style={{marginTop:'10px',marginBottom:'10px'}}>
          <tbody>
            <tr>
              <td
                style={{
                  backgroundColor: "#F7D707",
                }}
              >
                รอเข้ารับบริการ
              </td>
              <td>{Aalldatal}</td>
            </tr>
          </tbody>
        </table>
  );
}
