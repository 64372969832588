import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import { v4 as uuidv4 } from "uuid";
import "./ondate.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TextField,
  Grid,
} from "@mui/material";


export default function History({redata91,redata92,redata93,redata94,redata95,redata96,redata101,redata102,redata103,redata104,redata105,redata106,redata111,redata112,redata113,redata114,redata115,redata116,redata131,redata132,redata133,redata134,redata135,redata136,redata141,redata142,redata143,redata144,redata145,redata146}) {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  // const URLAlert = "https://lineapi.thaipadi.org";


  const [usersdata, setUsersdata] = useState([]);

  const getusers =()=>{
    const usersURL = `${URL}/users`;
    axios.get(`${usersURL}`).then((res) => {
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      res.data.shift();
      setUsersdata(res.data);
    });
  }

  const getdata = async () => {
    try {
      const appoinment = [{name:"appoinment",id:'1'},{name:"appoinments",id:'2'},{name:"appoinmentss",id:'3'},{name:"appoinmentsss",id:'4'},{name:"appoinmentssss",id:'5'},{name:"appoinmentsssss",id:'6'},{name:"passconfirmapp",id:'7'},{name:"passblockapp",id:'8'}];
      const api = appoinment.map((item) => {
        return `${URL}/${item.name}`;
      });
      const [res1, res2, res3, res4, res5, res6, res7, res8] = await Promise.all([axios.get(api[0]), axios.get(api[1]), axios.get(api[2]), axios.get(api[3]), axios.get(api[4]), axios.get(api[5]), axios.get(api[6]), axios.get(api[7])]);
      setAppdata(
        res1.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatas(
        res2.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatass(
        res3.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasss(
        res4.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatassss(
        res5.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setAppdatasssss(
        res6.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setPassconfirmapp(
        res7.data.filter((e) => {
          return e.TN > 9;
        })
      );
      setPassblockapp(
        res8.data.filter((e) => {
          return e.TN > 9;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getusers()
    getdata()
  }, [URL,redata91,redata92,redata93,redata94,redata95,redata96,redata101,redata102,redata103,redata104,redata105,redata106,redata111,redata112,redata113,redata114,redata115,redata116,redata131,redata132,redata133,redata134,redata135,redata136,redata141,redata142,redata143,redata144,redata145,redata146]);

  const [appdata, setAppdata] = useState([]);
  const [appdatas, setAppdatas] = useState([]);
  const [appdatass, setAppdatass] = useState([]);
  const [appdatasss, setAppdatasss] = useState([]);
  const [appdatassss, setAppdatassss] = useState([]);
  const [appdatasssss, setAppdatasssss] = useState([]);
  const [passconfirmapp, setPassconfirmapp] = useState([]);
  const [passblockapp, setPassblockapp] = useState([]);


  const [searchh, setSearchh] = useState(null);
  const testttt = (e) => {
    if (e.target.value === "") {
      return setSearchh(null);
    } else {
      return setSearchh(e.target.value);
    }
  };
  const appalldata = [
    ...appdata,
    ...appdatas,
    ...appdatass,
    ...appdatasss,
    ...appdatassss,
    ...appdatasssss,
    ...passconfirmapp,
    ...passblockapp,
  ];

  const [appsearch, setAppsearch] = useState(null);
  const apptesttt = (e) => {
    if (e.target.value === "") {
      return setAppsearch(null);
    } else {
      return setAppsearch(e.target.value);
    }
  };

  return (
    <div style={{marginTop:'10px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <h1>ค้นหานัดหมายผู้รับบริการ</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={apptesttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "200px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackdate"
                    >
                      วันที่
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "50px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbacktime"
                    >
                      เวลา
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        maxWidth: "90px",
                        padding: "0 0 0 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackname"
                    >
                      ชื่อ-สกุล
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "30px",
                        padding: "0 0 0 0",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackprovider"
                    >
                      เจ้าหน้าที่
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "30px",
                        padding: "5px 5px 5px 5px",
                        backgroundColor: "#1bb3b3",
                      }}
                      // className="searchbackrole"
                    >
                      สิทธิ์
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appalldata
                    .filter((e) => {
                      return (
                        e.fname.includes(appsearch) ||
                        e.lname.includes(appsearch) ||
                        e.TN.toString().includes(appsearch) ||
                        e.tel.includes(appsearch)
                      );
                    })
                    .map((item) => {
                      return (
                        <TableRow key={uuidv4()} className="searchusersrow">
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 1px 5px 1px",
                            }}
                            // className="searchbackdate"
                          >
                            {item.date}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "40px",
                              padding: "5px 0 5px 0",
                            }}
                            // className="searchbacktime"
                          >
                            {item.time} น.
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              maxWidth: "90px",
                              maxHeight: "10px",
                              padding: "0 0 0 10px",
                            }}
                            // className="searchbackname"
                          >
                            {item.prefix}
                            {item.fname} {item.lname}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "0 0 0 0",
                            }}
                            // className="searchbackprovider"
                          >
                            {item.provider}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "5px 5px 5px 5px",
                            }}
                            // className="searchbackrole"
                          >
                            {item.role}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper className="searchapp">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <h1>ค้นหาข้อมูลผู้รับบริการ</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={testttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "200px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#e79129",
                      }}
                      className="searchbacktn"
                    >
                      TN
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        padding: "5px 0 5px 0",
                        backgroundColor: "#e79129",
                      }}
                    >
                      ชื่อ-สกุล
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxHeight: "30px",
                        padding: "0 0 0 0",
                        backgroundColor: "#e79129",
                      }}
                    >
                      เบอร์โทรศัพท์
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "60px",
                        padding: "5px 5px 5px 5px",
                        backgroundColor: "#e79129",
                      }}
                      className="searchbackrole"
                    >
                      สิทธิ์
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersdata
                    .filter((e) => {
                      return (
                        e.fname.includes(searchh) ||
                        e.lname.includes(searchh) ||
                        e.TN.toString().includes(searchh) ||
                        e.tel.includes(searchh)
                      );
                    })
                    .map((item) => {
                      return (
                        <TableRow key={uuidv4()} className="searchbackrow">
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 0 5px 0",
                            }}
                            className="searchbacktn"
                          >
                            {item.TN}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxHeight: "30px", padding: "0 0 0 10px" }}
                          >
                            {item.prefix}
                            {item.fname} {item.lname}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "30px",
                              padding: "5px 0 5px 0",
                            }}
                          >
                            {item.tel}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              maxWidth: "60px",
                              padding: "5px 5px 5px 5px",
                            }}
                            className="searchbackrole"
                          >
                            {item.role}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
