import axios from "axios";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import * as React from "react";
import DatePicker from "react-date-picker";
import Footer from "../fronthome/footer/footer";
import { Box } from "@mui/system";
import { CssBaseline, Container, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { Grid, TextField } from "@mui/material";
import Navback from "./Navbarback";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import { EditOutlined, PostAddOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function Job({ startDate }) {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  const [an, setAn] = useState([]);
  const [job, setJob] = useState([]);
  const [status, setStatus] = useState([false]);
  const [fatstatus, setFatstatus] = useState(false);
  const [wastatus, setWastatus] = useState(false);
  const [wangstatus, setWangstatus] = useState(false);
  const [lastatus, setLastatus] = useState(false);
  const [dastatus, setDastatus] = useState(false);
  const [jistatus, setJistatus] = useState(false);
  const [nastatus, setNastatus] = useState(false);
  const [fastatus, setFastatus] = useState(false);
  const [sahstatus, setSahstatus] = useState(false);

  const getdata = async () => {
    const jobdata = [
      {
        fat: null,
        wa: null,
        wang: null,
        la: null,
        da: null,
        ji: null,
        na: null,
        fa: null,
        sah: null,
      },
    ];
    try {
      const api = `${URL}/job/${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;

      const getStatus = (name, data) => {
        switch (name) {
          case "Fat":
            if (data === "เวร") {
              setFatstatus(true);
              setFat(data);
              break;
            }
            setFatstatus(false);
            setFat(data);
            break;
          case "Wa":
            if (data === "เวร") {
              setWastatus(true);
              setWa(data);
              break;
            }
            setWastatus(false);
            setWa(data);
            break;
          case "Wang":
            if (data === "เวร") {
              setWangstatus(true);
              setWang(data);
              break;
            }
            setWangstatus(false);
            setWang(data);
            break;
          case "La":
            if (data === "เวร") {
              setLastatus(true);
              setLa(data);
              break;
            }
            setLastatus(false);
            setLa(data);
            break;
          case "Da":
            if (data === "เวร") {
              setDastatus(true);
              setDa(data);
              break;
            }
            setDastatus(false);
            setDa(data);
            break;
          case "Ji":
            if (data === "เวร") {
              setJistatus(true);
              setJi(data);
              break;
            }
            setJistatus(false);
            setJi(data);
            break;
          case "Na":
            if (data === "เวร") {
              setNastatus(true);
              setNa(data);
              break;
            }
            setNastatus(false);
            setNa(data);
            break;
          case "Fa":
            if (data === "เวร") {
              setFastatus(true);
              setFa(data);
              break;
            }
            setFastatus(false);
            setFa(data);
            break;
          case "Sah":
            if (data === "เวร") {
              setSahstatus(true);
              setSah(data);
              break;
            }
            setSahstatus(false);
            setSah(data);
            break;
          default:
            console.log("fail");
        }
      };

      const [res] = await Promise.all([axios.get(api)]);
      if (res.data.length === 0) {
        setJob(jobdata[0]);
        setStatus(false);
        return;
      }
      getStatus("Fat", res.data[0].fat);
      getStatus("Wa", res.data[0].wa);
      getStatus("Wang", res.data[0].wang);
      getStatus("La", res.data[0].la);
      getStatus("Da", res.data[0].da);
      getStatus("Ji", res.data[0].ji);
      getStatus("Na", res.data[0].na);
      getStatus("Fa", res.data[0].fa);
      getStatus("Sah", res.data[0].sah);
      setAn(res.data[0].AN);
      setJob(res.data[0]);
      setStatus(true);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, [URL, startDate]);

  const [fat, setFat] = useState("");
  const [wa, setWa] = useState("");
  const [wang, setWang] = useState("");
  const [la, setLa] = useState("");
  const [da, setDa] = useState("");
  const [ji, setJi] = useState("");
  const [na, setNa] = useState("");
  const [fa, setFa] = useState("");
  const [sah, setSah] = useState("");

  const [openaddjob, setOpenaddjob] = useState(false);
  const [openaddholidayjob, setOpenaddholidayjob] = useState(false);

  const [openeditjob, setOpeneditjob] = useState(false);
  const [openeditholidayjob, setOpeneditholidayjob] = useState(false);

  const submitjob = (e) => {
    e.preventDefault();
    const datejob = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    if (fat === "" && wa === "" && wang === "" && la === "" && da === "" && ji === "" && na === "" && fa === "" && sah === "") {
      console.log("test");
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "กรุณาระบุข้อมูลให้ถูกต้อง",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        return;
      });
    } else {
      axios
        .post(`${URL}/job`, {
          date: datejob,
          time: "",
          fat,
          wa,
          wang,
          la,
          da,
          ji,
          na,
          fa,
          sah,
          note: "",
        })
        .then(function (response) {
          if (response.data["status"] === "OK") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "เพิ่มตารางเเวรเรียบร้อย",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              getdata();
              setFat("");
              setWa("");
              setWang("");
              setLa("");
              setDa("");
              setJi("");
              setNa("");
              setFa("");
              setSah("");
              setOpenaddjob(false);
              setOpenaddholidayjob(false);
              return;
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ผิดพลาด",
              text: "ไม่สามารถเพิ่มตารางเวรได้",
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              getdata();
              console.log(response.data);
              return;
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
      return;
    }
  };

  const submiteditjob = (e) => {
    e.preventDefault();
    if (fat === "" && wa === "" && wang === "" && la === "" && da === "" && ji === "" && na === "" && fa === "" && sah === "") {
      Swal.fire({
        title: "ท่าต้องการลบตารางเวรใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ปิด",
      }).then((result) => {
        if (result.isConfirmed) {
          var data = JSON.stringify({
            AN: an,
          });
          var config = {
            method: "delete",
            url: `${URL}/job`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: "เสร็จสิ้น",
                text: "ทำการลบเรียบร้อย",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                setFat("");
                setWa("");
                setWang("");
                setLa("");
                setDa("");
                setJi("");
                setNa("");
                setFa("");
                setSah("");
                getdata();
                setOpeneditjob(false);
                setOpeneditholidayjob(false);
                return;
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    } else {
      axios
        .put(`${URL}/job`, {
          time: "",
          fat,
          wa,
          wang,
          la,
          da,
          ji,
          na,
          fa,
          sah,
          note: 1,
          AN: an,
        })
        .then(function (response) {
          if (response.data["status"] === "OK") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "แก้ไขตารางเเวรเรียบร้อย",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              setFat("");
              setWa("");
              setWang("");
              setLa("");
              setDa("");
              setJi("");
              setNa("");
              setFa("");
              setSah("");
              getdata();
              setOpeneditjob(false);
              setOpeneditholidayjob(false);
              return;
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ผิดพลาด",
              text: "ไม่สามารถเพิ่มตารางเวรได้",
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              getdata();
              console.log(response.data);
              return;
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };

  const getOpenjob = () => {
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      setOpenaddholidayjob(true);
      return;
    } else {
      setOpenaddjob(true);
      return;
    }
  };

  const getOpeneditjob = () => {
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      setOpeneditholidayjob(true);
      return;
    } else {
      setOpeneditjob(true);
      return;
    }
  };

  const setdatajob = (name, status) => {
    switch (name) {
      case "Fat":
        if (status) {
          setFat("เวร");
          break;
        }
        setFat("");
        break;
      case "Wa":
        if (status) {
          setWa("เวร");
          break;
        }
        setWa("");
        break;
      case "Wang":
        if (status) {
          setWang("เวร");
          break;
        }
        setWang("");
        break;
      case "La":
        if (status) {
          setLa("เวร");
          break;
        }
        setLa("");
        break;
      case "Da":
        if (status) {
          setDa("เวร");
          break;
        }
        setDa("");
        break;
      case "Ji":
        if (status) {
          setJi("เวร");
          break;
        }
        setJi("");
        break;
      case "Na":
        if (status) {
          setNa("เวร");
          break;
        }
        setNa("");
        break;
      case "Fa":
        if (status) {
          setFa("เวร");
          break;
        }
        setFa("");
        break;
      case "Sah":
        if (status) {
          setSah("เวร");
          break;
        }
        setSah("");
        break;
      default:
        console.log("fail");
    }
  };

  return (
    <div className="hidden 2xl:flex">
      <TableContainer style={{ marginBottom: "10px" }}>
        <Table>
          <thead className="scheduletophead">
            <tr>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">อารอฟัต</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">อานีรา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">อาซูวา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">ฟาตีเม๊าะ</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">นูรไอดา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">วันอารีนา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">สีตีฟาตีเมาะ</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">ฟาอีซา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">ซัลมา</th>
              <th className="text-xs w-[10%] h-8 bg-[#1bb3b3]">สถานะ</th>
            </tr>
          </thead>
          <tbody className="scheduletopbody">
            <tr>
              <td className="h-8">{job.fat}</td>
              <td className="h-8">{job.wa}</td>
              <td className="h-8">{job.wang}</td>
              <td className="h-8">{job.la}</td>
              <td className="h-8">{job.da}</td>
              <td className="h-8">{job.ji}</td>
              <td className="h-8">{job.na}</td>
              <td className="h-8">{job.fa}</td>
              <td className="h-8">{job.sah}</td>
              {status ? (
                <td className="h-8">
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      getOpeneditjob();
                      return;
                    }}
                  >
                    แก้ไข
                  </Button>
                </td>
              ) : (
                <td className="h-8">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      getOpenjob();
                      return;
                    }}
                  >
                    กำหนด
                  </Button>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <Modal open={openaddjob} onClose={() => setOpenaddjob(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form onSubmit={submitjob}>
            <div className="flex items-center justify-center p-4 border-b border-solid border-blueGray-200 rounded-t">
              <div>
                <p className="text-lg lg:text-3xl text-center">วันทำการ</p>
                <p className="text-lg lg:text-2xl text-center">
                  {startDate.toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
            {/*body*/}
            <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
              <div className="w-1/2">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="อารอฟัต"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setFat(event.target.value);
                      }}
                      value={fat}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="อานีรา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setWa(event.target.value);
                      }}
                      value={wa}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="อาซูวา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setWang(event.target.value);
                      }}
                      value={wang}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="ml-4 w-1/2">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="ฟาตีเม๊าะ"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setLa(event.target.value);
                      }}
                      value={la}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="นูรไอดา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setDa(event.target.value);
                      }}
                      value={da}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="วันอารีนา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setJi(event.target.value);
                      }}
                      value={ji}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="สีตีฟาตีเม๊าะ"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setNa(event.target.value);
                      }}
                      value={na}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="ฟาอีซา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setFa(event.target.value);
                      }}
                      value={fa}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="ซัลมา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setSah(event.target.value);
                      }}
                      value={sah}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
              <Button type="submit" variant="contained" color="success" style={{ marginRight: "10px" }}>
                บันทึก
              </Button>
              <Button onClick={() => setOpenaddjob(false)} variant="contained" color="error">
                ปิด
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={openaddholidayjob} onClose={() => setOpenaddholidayjob(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <CssBaseline />
          <Container>
            <form onSubmit={submitjob}>
              <div className="flex items-center justify-center p-4 border-b border-solid border-blueGray-200 rounded-t">
                <div>
                  <p className="text-lg lg:text-3xl text-center">ตารางเวรประจำวันที่</p>
                  <p className="text-lg lg:text-2xl text-center">
                    {startDate.toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </div>
              {/*body*/}
              <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFat("เวร");
                              return;
                            }
                            setFat("");
                            return;
                          }}
                        />
                      }
                      label="อารอฟัต"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setWa("เวร");
                              return;
                            }
                            setWa("");
                            return;
                          }}
                        />
                      }
                      label="อานีรา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setWang("เวร");
                              return;
                            }
                            setWang("");
                            return;
                          }}
                        />
                      }
                      label="อาซูวา"
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLa("เวร");
                              return;
                            }
                            setLa("");
                            return;
                          }}
                        />
                      }
                      label="ฟาตีเม๊าะ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDa("เวร");
                              return;
                            }
                            setDa("");
                            return;
                          }}
                        />
                      }
                      label="นูรไอดา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setJi("เวร");
                              return;
                            }
                            setJi("");
                            return;
                          }}
                        />
                      }
                      label="วันอารีนา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setNa("เวร");
                              return;
                            }
                            setNa("");
                            return;
                          }}
                        />
                      }
                      label="สีตีฟาตีเม๊าะ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFa("เวร");
                              return;
                            }
                            setFa("");
                            return;
                          }}
                        />
                      }
                      label="ฟาอีซา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSah("เวร");
                              return;
                            }
                            setSah("");
                            return;
                          }}
                        />
                      }
                      label="ซัลมา"
                    />
                  </FormGroup>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
                <Button type="submit" variant="contained" color="success" style={{ marginRight: "10px" }}>
                  บันทึก
                </Button>
                <Button onClick={() => setOpenaddholidayjob(false)} variant="contained" color="error">
                  ปิด
                </Button>
              </div>
            </form>
          </Container>
        </Box>
      </Modal>
      <Modal open={openeditjob} onClose={() => setOpeneditjob(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form onSubmit={submiteditjob}>
            <div className="flex items-center justify-center p-4 border-b border-solid border-blueGray-200 rounded-t">
              <div>
                <p className="text-lg lg:text-3xl text-center">วันทำการ</p>
                <p className="text-lg lg:text-2xl text-center">
                  {startDate.toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
            {/*body*/}
            <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="อารอฟัต"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setFat(event.target.value);
                      }}
                      value={fat}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="อานีรา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setWa(event.target.value);
                      }}
                      value={wa}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="อาซูวา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setWang(event.target.value);
                      }}
                      value={wang}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="ฟาตีเม๊าะ"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setLa(event.target.value);
                      }}
                      value={la}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="นูรไอดา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setDa(event.target.value);
                      }}
                      value={da}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="วันอารีนา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setJi(event.target.value);
                      }}
                      value={ji}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="สีตีฟาตีเม๊าะ"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setNa(event.target.value);
                      }}
                      value={na}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="ฟาอีซา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setFa(event.target.value);
                      }}
                      value={fa}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="ซัลมา"
                      type="text"
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setSah(event.target.value);
                      }}
                      value={sah}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
              <Button type="submit" variant="contained" color="success" style={{ marginRight: "10px" }}>
                บันทึก
              </Button>
              <Button onClick={() => setOpeneditjob(false)} variant="contained" color="error">
                ปิด
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal open={openeditholidayjob} onClose={() => setOpeneditholidayjob(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <CssBaseline />
          <Container>
            <form onSubmit={submiteditjob}>
              <div className="flex items-center justify-center p-4 border-b border-solid border-blueGray-200 rounded-t">
                <div>
                  <p className="text-lg lg:text-3xl text-center">ตารางเวรประจำวันที่</p>
                  <p className="text-lg lg:text-2xl text-center">
                    {startDate.toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </div>
              {/*body*/}
              <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fatstatus}
                          color="success"
                          onChange={(event) => {
                            setFatstatus(event.target.checked);
                            setdatajob("Fat", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="อารอฟัต"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wastatus}
                          color="success"
                          onChange={(event) => {
                            setWastatus(event.target.checked);
                            setdatajob("Wa", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="อานีรา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wangstatus}
                          color="success"
                          onChange={(event) => {
                            setWangstatus(event.target.checked);
                            setdatajob("Wang", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="อาซูวา"
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lastatus}
                          color="secondary"
                          onChange={(event) => {
                            setLastatus(event.target.checked);
                            setdatajob("La", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="ฟาตีเม๊าะ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dastatus}
                          color="secondary"
                          onChange={(event) => {
                            setDastatus(event.target.checked);
                            setdatajob("Da", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="นูรไอดา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={jistatus}
                          color="secondary"
                          onChange={(event) => {
                            setJistatus(event.target.checked);
                            setdatajob("Ji", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="วันอารีนา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={nastatus}
                          color="secondary"
                          onChange={(event) => {
                            setNastatus(event.target.checked);
                            setdatajob("Na", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="สีตีฟาตีเม๊าะ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fastatus}
                          color="secondary"
                          onChange={(event) => {
                            setFastatus(event.target.checked);
                            setdatajob("Fa", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="ฟาอีซา"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sahstatus}
                          color="secondary"
                          onChange={(event) => {
                            setSahstatus(event.target.checked);
                            setdatajob("Sah", event.target.checked);
                            return;
                          }}
                        />
                      }
                      label="ซัลมา"
                    />
                  </FormGroup>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b">
                <Button type="submit" variant="contained" color="success" style={{ marginRight: "10px" }}>
                  บันทึก
                </Button>
                <Button
                  onClick={() => {
                    getdata();
                    setOpeneditholidayjob(false);
                    return;
                  }}
                  variant="contained"
                  color="error"
                >
                  ปิด
                </Button>
              </div>
            </form>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
