import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import DatePicker from "react-date-picker";
import { Button } from "@mui/material";
import { Block, CancelOutlined, EventAvailable, EventBusy, Send, NotificationAdd, Search } from "@mui/icons-material/";
import "./ondate.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { Container, TextField, Grid, CssBaseline, Typography, FormControl, InputLabel, Select, MenuItem, Modal } from "@mui/material";
import Swal from "sweetalert2";
import Note from "./note";
import History from "./history";
import Wait from "./wait";
import Searchrole from "./Searchrole";
import Job from "./job";
import Searchtel from "./Searchtel";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  boxShadow: 24,
  p: 1,
};

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  boxShadow: 24,
  p: 1,
};

export default function One() {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const URLAlert = "https://lineapi.thaipadi.org";

  //date
  const [startDate, setStartDate] = useState(new Date());
  const [dayname, setDayname] = useState("");
  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);
  //date

  //getdata
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`${URL}/users`).then((res) => {
      res.data.shift();
      res.data.shift();
      setData(res.data);
    });
  }, [URL]);
  //getdata

  //search
  const [search, setSearch] = useState("");
  const testtt = (e) => {
    return setSearch(e.target.value);
  };
  //search

  //แจ้งเตือนไลน์
  const newblocklist = () => {
    var config = {
      method: "post",
      url: `${URLAlert}/newblocklist`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const newconfirmlist = () => {
    var config = {
      method: "post",
      url: `${URLAlert}/newconfirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const linealert = () => {
    Swal.fire({
      title: "ยืนยันการแจ้งเตือน",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        var config = {
          method: "post",
          url: `${URLAlert}/alertline`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };
  //แจ้งเตือนไลน์

  //ลบวันหยุด
  const deleteholiday = (holidayTN) => {
    if (holidayTN === 7) {
      var data = JSON.stringify({
        date: startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      });

      var config = {
        method: "delete",
        url: `${URL}/holiday`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data["status"] === "OK") {
            recheck();
            return;
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ลบข้อมูลวันหยุดผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              return;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      return;
    }
  };
  //ลบวันหยุด

  //เพิ่มวันหยุด
  const addholiday = (appurl, appid) => {
    const date = startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
    if (selecttn === 7) {
      var data = JSON.stringify({
        date: startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        dateget: date,
      });
      var config = {
        method: "post",
        url: `${URL}/holidays`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data.status));
          if (response.data["status"] === "OK") {
            recheckholiday();
            return;
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "เพิ่มข้อมูลวันหยุดผิดพลาด",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              return;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      return;
    } else {
      return;
    }
  };
  //เพิ่มวันหยุด

  //clerdata
  const addappoinment = (appurl, appid, timestring, timeid) => {
    var data = JSON.stringify({
      TN: selecttn,
      date: selectdate,
      time: timestring,
      service: service,
      provider: provider,
      dateapp: startDate,
    });

    var config = {
      method: "post",
      url: `${URL}/${appurl}s`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "นัดหมายเรียบร้อย ",
          text: "กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck(appurl, appid, timeid);
          setOpen(false);
        });
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const cleardata = (AN, appurl, appid, appconfirm, timeid) => {
    var data = JSON.stringify({
      TN: 1,
    });

    var config = {
      method: "put",
      url: `${URL}/${appconfirm}s/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการเข้ารับบริการเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck(appurl, appid, timeid);
          newconfirmlist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addconfirmlist = (TN, AN, date, time, provider, appurl, appid, appconfirm, timeid) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/confirmlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        cleardata(AN, appurl, appid, appconfirm, timeid);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const canceldata = (AN, appurl, appid, appcancel, timeid) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/${appcancel}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ทำการยกเลิกเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck(appurl, appid, timeid);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addconfirmblocklist = (AN, appurl, appid, appconfirm, timeid) => {
    var data = JSON.stringify({
      TN: 2,
    });

    var config = {
      method: "put",
      url: `${URL}/${appconfirm}s/${AN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: "success",
          title: "เสร็จสิ้น",
          text: "ยืนยันการผิดนัดเรียบร้อย",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          recheck(appurl, appid, timeid);
          newblocklist();
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addblocklist = (TN, AN, date, time, provider, appurl, appid, appblock, appconfirm, timeid) => {
    const setdate = startDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const splitdate = setdate.split(" ");
    const report = `${splitdate[1]} ${splitdate[2]}`;
    const month = `${splitdate[1]}`;
    const year = `${splitdate[2]}`;

    var data = JSON.stringify({
      TN: TN,
      date: date,
      time: time,
      provider: provider,
      report: report,
      month: month,
      year: year,
    });

    var config = {
      method: "post",
      url: `${URL}/${appblock}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        addconfirmblocklist(AN, appurl, appid, appconfirm, timeid);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const recheck = (appurl, appid, timeid) => {
    const PostURL = `${URL}/${appurl}/${timeid}/${selectdate}`;
    axios.get(PostURL).then((res) => {
      switch (appid) {
        case "91":
          setReData91(res.data[0]);
          break;
        case "92":
          setReData92(res.data[0]);
          break;
        case "93":
          setReData93(res.data[0]);
          break;
        case "94":
          setReData94(res.data[0]);
          break;
        case "95":
          setReData95(res.data[0]);
          break;
        case "96":
          setReData96(res.data[0]);
          break;
        case "101":
          setReData101(res.data[0]);
          break;
        case "102":
          setReData102(res.data[0]);
          break;
        case "103":
          setReData103(res.data[0]);
          break;
        case "104":
          setReData104(res.data[0]);
          break;
        case "105":
          setReData105(res.data[0]);
          break;
        case "106":
          setReData106(res.data[0]);
          break;
        case "111":
          setReData111(res.data[0]);
          break;
        case "112":
          setReData112(res.data[0]);
          break;
        case "113":
          setReData113(res.data[0]);
          break;
        case "114":
          setReData114(res.data[0]);
          break;
        case "115":
          setReData115(res.data[0]);
          break;
        case "116":
          setReData116(res.data[0]);
          break;
        case "131":
          setReData131(res.data[0]);
          break;
        case "132":
          setReData132(res.data[0]);
          break;
        case "133":
          setReData133(res.data[0]);
          break;
        case "134":
          setReData134(res.data[0]);
          break;
        case "135":
          setReData135(res.data[0]);
          break;
        case "136":
          setReData136(res.data[0]);
          break;
        case "141":
          setReData141(res.data[0]);
          break;
        case "142":
          setReData142(res.data[0]);
          break;
        case "143":
          setReData143(res.data[0]);
          break;
        case "144":
          setReData144(res.data[0]);
          break;
        case "145":
          setReData145(res.data[0]);
          break;
        case "146":
          setReData146(res.data[0]);
          break;
        default:
        // code block
      }
    });
  };
  //clerdata

  //seturl,id
  // const time9 = ["09:00", "9"];
  // const time10 = ["10:15", "10"];
  const time9 = ["08:50", "9"];
  const time10 = ["09:55", "10"];
  const time11 = ["11:00", "11"];
  const time13 = ["13:30", "13"];
  const time14 = ["14:45", "14"];
  const [appUrl, setAppUrl] = useState([]);
  const [appId, setAppId] = useState([]);
  const [timeString, setTimeString] = useState([]);
  const [timeId, setTimeId] = useState([]);
  //seturl,id

  //91
  const [data91, setData91] = useState([]);
  const [reData91, setReData91] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [status91, setStatus91] = useState("ว่าง");
  const [cfer91, setCfer91] = useState(true);
  const [cancel91, setCancel91] = useState(true);

  //92
  const [data92, setData92] = useState([]);
  const [reData92, setReData92] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [status92, setStatus92] = useState("ว่าง");
  const [cfer92, setCfer92] = useState(true);
  const [cancel92, setCancel92] = useState(true);

  //93
  const [data93, setData93] = useState([]);
  const [reData93, setReData93] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [status93, setStatus93] = useState("ว่าง");
  const [cfer93, setCfer93] = useState(true);
  const [cancel93, setCancel93] = useState(true);

  //94
  const [data94, setData94] = useState([]);
  const [reData94, setReData94] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [status94, setStatus94] = useState("ว่าง");
  const [cfer94, setCfer94] = useState(true);
  const [cancel94, setCancel94] = useState(true);

  //95
  const [data95, setData95] = useState([]);
  const [reData95, setReData95] = useState([]);
  const [editButton95, setEditButton95] = useState(false);
  const [icon95, setIcon95] = useState(<EventAvailable />);
  const [status95, setStatus95] = useState("ว่าง");
  const [cfer95, setCfer95] = useState(true);
  const [cancel95, setCancel95] = useState(true);

  //96
  const [data96, setData96] = useState([]);
  const [reData96, setReData96] = useState([]);
  const [editButton96, setEditButton96] = useState(false);
  const [icon96, setIcon96] = useState(<EventAvailable />);
  const [status96, setStatus96] = useState("ว่าง");
  const [cfer96, setCfer96] = useState(true);
  const [cancel96, setCancel96] = useState(true);

  //101
  const [data101, setData101] = useState([]);
  const [reData101, setReData101] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [status101, setStatus101] = useState("ว่าง");
  const [cfer101, setCfer101] = useState(true);
  const [cancel101, setCancel101] = useState(true);

  //102
  const [data102, setData102] = useState([]);
  const [reData102, setReData102] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [status102, setStatus102] = useState("ว่าง");
  const [cfer102, setCfer102] = useState(true);
  const [cancel102, setCancel102] = useState(true);

  //103
  const [data103, setData103] = useState([]);
  const [reData103, setReData103] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [status103, setStatus103] = useState("ว่าง");
  const [cfer103, setCfer103] = useState(true);
  const [cancel103, setCancel103] = useState(true);

  //104
  const [data104, setData104] = useState([]);
  const [reData104, setReData104] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [status104, setStatus104] = useState("ว่าง");
  const [cfer104, setCfer104] = useState(true);
  const [cancel104, setCancel104] = useState(true);

  //105
  const [data105, setData105] = useState([]);
  const [reData105, setReData105] = useState([]);
  const [editButton105, setEditButton105] = useState(false);
  const [icon105, setIcon105] = useState(<EventAvailable />);
  const [status105, setStatus105] = useState("ว่าง");
  const [cfer105, setCfer105] = useState(true);
  const [cancel105, setCancel105] = useState(true);

  //106
  const [data106, setData106] = useState([]);
  const [reData106, setReData106] = useState([]);
  const [editButton106, setEditButton106] = useState(false);
  const [icon106, setIcon106] = useState(<EventAvailable />);
  const [status106, setStatus106] = useState("ว่าง");
  const [cfer106, setCfer106] = useState(true);
  const [cancel106, setCancel106] = useState(true);

  //111
  const [data111, setData111] = useState([]);
  const [reData111, setReData111] = useState([]);
  const [editButton111, setEditButton111] = useState(false);
  const [icon111, setIcon111] = useState(<EventAvailable />);
  const [status111, setStatus111] = useState("ว่าง");
  const [cfer111, setCfer111] = useState(true);
  const [cancel111, setCancel111] = useState(true);

  //112
  const [data112, setData112] = useState([]);
  const [reData112, setReData112] = useState([]);
  const [editButton112, setEditButton112] = useState(false);
  const [icon112, setIcon112] = useState(<EventAvailable />);
  const [status112, setStatus112] = useState("ว่าง");
  const [cfer112, setCfer112] = useState(true);
  const [cancel112, setCancel112] = useState(true);

  //113
  const [data113, setData113] = useState([]);
  const [reData113, setReData113] = useState([]);
  const [editButton113, setEditButton113] = useState(false);
  const [icon113, setIcon113] = useState(<EventAvailable />);
  const [status113, setStatus113] = useState("ว่าง");
  const [cfer113, setCfer113] = useState(true);
  const [cancel113, setCancel113] = useState(true);

  //114
  const [data114, setData114] = useState([]);
  const [reData114, setReData114] = useState([]);
  const [editButton114, setEditButton114] = useState(false);
  const [icon114, setIcon114] = useState(<EventAvailable />);
  const [status114, setStatus114] = useState("ว่าง");
  const [cfer114, setCfer114] = useState(true);
  const [cancel114, setCancel114] = useState(true);

  //115
  const [data115, setData115] = useState([]);
  const [reData115, setReData115] = useState([]);
  const [editButton115, setEditButton115] = useState(false);
  const [icon115, setIcon115] = useState(<EventAvailable />);
  const [status115, setStatus115] = useState("ว่าง");
  const [cfer115, setCfer115] = useState(true);
  const [cancel115, setCancel115] = useState(true);

  //116
  const [data116, setData116] = useState([]);
  const [reData116, setReData116] = useState([]);
  const [editButton116, setEditButton116] = useState(false);
  const [icon116, setIcon116] = useState(<EventAvailable />);
  const [status116, setStatus116] = useState("ว่าง");
  const [cfer116, setCfer116] = useState(true);
  const [cancel116, setCancel116] = useState(true);

  //131
  const [data131, setData131] = useState([]);
  const [reData131, setReData131] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [status131, setStatus131] = useState("ว่าง");
  const [cfer131, setCfer131] = useState(true);
  const [cancel131, setCancel131] = useState(true);

  //132
  const [data132, setData132] = useState([]);
  const [reData132, setReData132] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [status132, setStatus132] = useState("ว่าง");
  const [cfer132, setCfer132] = useState(true);
  const [cancel132, setCancel132] = useState(true);

  //133
  const [data133, setData133] = useState([]);
  const [reData133, setReData133] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [status133, setStatus133] = useState("ว่าง");
  const [cfer133, setCfer133] = useState(true);
  const [cancel133, setCancel133] = useState(true);

  //134
  const [data134, setData134] = useState([]);
  const [reData134, setReData134] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [status134, setStatus134] = useState("ว่าง");
  const [cfer134, setCfer134] = useState(true);
  const [cancel134, setCancel134] = useState(true);

  //135
  const [data135, setData135] = useState([]);
  const [reData135, setReData135] = useState([]);
  const [editButton135, setEditButton135] = useState(false);
  const [icon135, setIcon135] = useState(<EventAvailable />);
  const [status135, setStatus135] = useState("ว่าง");
  const [cfer135, setCfer135] = useState(true);
  const [cancel135, setCancel135] = useState(true);

  //136
  const [data136, setData136] = useState([]);
  const [reData136, setReData136] = useState([]);
  const [editButton136, setEditButton136] = useState(false);
  const [icon136, setIcon136] = useState(<EventAvailable />);
  const [status136, setStatus136] = useState("ว่าง");
  const [cfer136, setCfer136] = useState(true);
  const [cancel136, setCancel136] = useState(true);

  //141
  const [data141, setData141] = useState([]);
  const [reData141, setReData141] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [status141, setStatus141] = useState("ว่าง");
  const [cfer141, setCfer141] = useState(true);
  const [cancel141, setCancel141] = useState(true);

  //142
  const [data142, setData142] = useState([]);
  const [reData142, setReData142] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [status142, setStatus142] = useState("ว่าง");
  const [cfer142, setCfer142] = useState(true);
  const [cancel142, setCancel142] = useState(true);

  //143
  const [data143, setData143] = useState([]);
  const [reData143, setReData143] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [status143, setStatus143] = useState("ว่าง");
  const [cfer143, setCfer143] = useState(true);
  const [cancel143, setCancel143] = useState(true);

  //144
  const [data144, setData144] = useState([]);
  const [reData144, setReData144] = useState([]);
  const [editButton144, setEditButton144] = useState(false);
  const [icon144, setIcon144] = useState(<EventAvailable />);
  const [status144, setStatus144] = useState("ว่าง");
  const [cfer144, setCfer144] = useState(true);
  const [cancel144, setCancel144] = useState(true);

  //145
  const [data145, setData145] = useState([]);
  const [reData145, setReData145] = useState([]);
  const [editButton145, setEditButton145] = useState(false);
  const [icon145, setIcon145] = useState(<EventAvailable />);
  const [status145, setStatus145] = useState("ว่าง");
  const [cfer145, setCfer145] = useState(true);
  const [cancel145, setCancel145] = useState(true);

  //146
  const [data146, setData146] = useState([]);
  const [reData146, setReData146] = useState([]);
  const [editButton146, setEditButton146] = useState(false);
  const [icon146, setIcon146] = useState(<EventAvailable />);
  const [status146, setStatus146] = useState("ว่าง");
  const [cfer146, setCfer146] = useState(true);
  const [cancel146, setCancel146] = useState(true);

  //api

  const getdata = async () => {
    try {
      const date = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;

      const appoinment = [
        { name: "appoinment", id: "9" },
        { name: "appoinments", id: "9" },
        { name: "appoinmentss", id: "9" },
        { name: "appoinmentsss", id: "9" },
        { name: "appoinmentssss", id: "9" },
        { name: "appoinmentsssss", id: "9" },
        { name: "appoinment", id: "10" },
        { name: "appoinments", id: "10" },
        { name: "appoinmentss", id: "10" },
        { name: "appoinmentsss", id: "10" },
        { name: "appoinmentssss", id: "10" },
        { name: "appoinmentsssss", id: "10" },
        { name: "appoinment", id: "11" },
        { name: "appoinments", id: "11" },
        { name: "appoinmentss", id: "11" },
        { name: "appoinmentsss", id: "11" },
        { name: "appoinmentssss", id: "11" },
        { name: "appoinmentsssss", id: "11" },
        { name: "appoinment", id: "13" },
        { name: "appoinments", id: "13" },
        { name: "appoinmentss", id: "13" },
        { name: "appoinmentsss", id: "13" },
        { name: "appoinmentssss", id: "13" },
        { name: "appoinmentsssss", id: "13" },
        { name: "appoinment", id: "14" },
        { name: "appoinments", id: "14" },
        { name: "appoinmentss", id: "14" },
        { name: "appoinmentsss", id: "14" },
        { name: "appoinmentssss", id: "14" },
        { name: "appoinmentsssss", id: "14" },
      ];

      const api = appoinment.map((item) => {
        return `${URL}/${item.name}/${item.id}/${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
      });

      const [res91, res92, res93, res94, res95, res96, res101, res102, res103, res104, res105, res106, res111, res112, res113, res114, res115, res116, res131, res132, res133, res134, res135, res136, res141, res142, res143, res144, res145, res146] = await Promise.all([
        axios.get(api[0]),
        axios.get(api[1]),
        axios.get(api[2]),
        axios.get(api[3]),
        axios.get(api[4]),
        axios.get(api[5]),
        axios.get(api[6]),
        axios.get(api[7]),
        axios.get(api[8]),
        axios.get(api[9]),
        axios.get(api[10]),
        axios.get(api[11]),
        axios.get(api[12]),
        axios.get(api[13]),
        axios.get(api[14]),
        axios.get(api[15]),
        axios.get(api[16]),
        axios.get(api[17]),
        axios.get(api[18]),
        axios.get(api[19]),
        axios.get(api[20]),
        axios.get(api[21]),
        axios.get(api[22]),
        axios.get(api[23]),
        axios.get(api[24]),
        axios.get(api[25]),
        axios.get(api[26]),
        axios.get(api[27]),
        axios.get(api[28]),
        axios.get(api[29]),
      ]);
      const setdata = (res, id, time, TN) => {
        const roletoken = localStorage.getItem("role");
        const defaultdata = [
          {
            AN: null,
            TN: null,
            fname: "ว่าง",
            lname: null,
            service: "ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            fname: null,
            lname: null,
            service: null,
            provider: null,
            date: null,
            time: null,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ปิดบริการ",
            lname: "วันหยุดราชการ",
            service: "วันหยุดราชการหรืออื่นๆ",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "หลังคลอด / พิเศษ",
            lname: null,
            service: "หลังคลอด / พิเศษ",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
        ];
        const dateInPast = function (firstDate, secondDate) {
          if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
            return true;
          }
          return false;
        };
        const holidaysURL = `${URL}/holidays`;
        switch (id) {
          case "91":
            if (res.data.length === 0) {
              setData91(defaultdata[0]);
              setEditButton91(false);
              setIcon91(<EventAvailable />);
              setStatus91("ว่างงง");
              setCancel91(true);
              setCfer91(true);
              return;
            } else if (res.data.length >= 1) {
              setData91(res.data[0]);
              setEditButton91(true);
              setIcon91(<EventBusy />);
              setStatus91("ไม่ว่าง");
              if (dateInPast(startDate, new Date())) {
                if (TN[0] === 1 || TN[0] === 2) {
                  setCfer91(true);
                  setCancel91(true);
                  return;
                } else if (TN[0] > 2 && TN[0] < 10) {
                  setCfer91(true);
                  setCancel91(false);
                  return;
                } else if (TN[0] > 10) {
                  setCfer91(false);
                  setCancel91(false);
                  return;
                } else {
                  setCfer91(false);
                  setCancel91(false);
                  return;
                }
              } else {
                setCfer91(true);
                setCancel91(false);
                return;
              }
            }
            break;
          case "92":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData92(defaultdata[0]);
                  setEditButton92(false);
                  setIcon92(<EventAvailable />);
                  setStatus92("ว่างงง");
                  setCancel92(true);
                  setCfer92(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData92(res.data[0]);
                  setEditButton92(true);
                  setIcon92(<EventBusy />);
                  setStatus92("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer92(true);
                      setCancel92(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer92(true);
                      setCancel92(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer92(false);
                      setCancel92(false);
                      return;
                    } else {
                      setCfer92(false);
                      setCancel92(false);
                      return;
                    }
                  } else {
                    setCfer92(true);
                    setCancel92(false);
                    return;
                  }
                }
              } else {
                setData92(defaultdata[2]);
                setEditButton92(true);
                setIcon92(<EventBusy />);
                setStatus92("ไม่ว่าง");
                setCfer92(true);
                setCancel92(true);
                return;
              }
            });
            break;
          case "93":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  // if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                  //   setData93(defaultdata[1]);
                  //   setEditButton93(true);
                  //   setIcon93(<EventBusy />);
                  //   setStatus93("ไม่ว่าง");
                  //   setCancel93(true);
                  //   setCfer93(true);
                  //   return;
                  // }
                  setData93(defaultdata[0]);
                  setEditButton93(false);
                  setIcon93(<EventAvailable />);
                  setStatus93("ว่างงง");
                  setCancel93(true);
                  setCfer93(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData93(res.data[0]);
                  setEditButton93(true);
                  setIcon93(<EventBusy />);
                  setStatus93("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer93(true);
                      setCancel93(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer93(true);
                      setCancel93(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer93(false);
                      setCancel93(false);
                      return;
                    } else {
                      setCfer93(false);
                      setCancel93(false);
                      return;
                    }
                  } else {
                    setCfer93(true);
                    setCancel93(false);
                    return;
                  }
                }
              } else {
                setData93(defaultdata[2]);
                setEditButton93(true);
                setIcon93(<EventBusy />);
                setStatus93("ไม่ว่าง");
                setCfer93(true);
                setCancel93(true);
                return;
              }
            });
            break;
          case "94":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData94(defaultdata[1]);
                    setEditButton94(true);
                    setIcon94(<EventBusy />);
                    setStatus94("ไม่ว่าง");
                    setCancel94(true);
                    setCfer94(true);
                    return;
                  }
                  setData94(defaultdata[0]);
                  setEditButton94(false);
                  setIcon94(<EventAvailable />);
                  setStatus94("ว่างงง");
                  setCancel94(true);
                  setCfer94(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData94(res.data[0]);
                  setEditButton94(true);
                  setIcon94(<EventBusy />);
                  setStatus94("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer94(true);
                      setCancel94(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer94(true);
                      setCancel94(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer94(false);
                      setCancel94(false);
                      return;
                    } else {
                      setCfer94(false);
                      setCancel94(false);
                      return;
                    }
                  } else {
                    setCfer94(true);
                    setCancel94(false);
                    return;
                  }
                }
              } else {
                setData94(defaultdata[2]);
                setEditButton94(true);
                setIcon94(<EventBusy />);
                setStatus94("ไม่ว่าง");
                setCfer94(true);
                setCancel94(true);
                return;
              }
            });
            break;
          case "95":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData95(defaultdata[1]);
                    setEditButton95(true);
                    setIcon95(<EventBusy />);
                    setStatus95("ไม่ว่าง");
                    setCancel95(true);
                    setCfer95(true);
                    return;
                  }
                  setData95(defaultdata[0]);
                  setEditButton95(false);
                  setIcon95(<EventAvailable />);
                  setStatus95("ว่างงง");
                  setCancel95(true);
                  setCfer95(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData95(res.data[0]);
                  setEditButton95(true);
                  setIcon95(<EventBusy />);
                  setStatus95("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer95(true);
                      setCancel95(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer95(true);
                      setCancel95(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer95(false);
                      setCancel95(false);
                      return;
                    } else {
                      setCfer95(false);
                      setCancel95(false);
                      return;
                    }
                  } else {
                    setCfer95(true);
                    setCancel95(false);
                    return;
                  }
                }
              } else {
                setData95(defaultdata[2]);
                setEditButton95(true);
                setIcon95(<EventBusy />);
                setStatus95("ไม่ว่าง");
                setCfer95(true);
                setCancel95(true);
                return;
              }
            });
            break;
          case "96":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData96(defaultdata[1]);
                    setEditButton96(true);
                    setIcon96(<EventBusy />);
                    setStatus96("ไม่ว่าง");
                    setCancel96(true);
                    setCfer96(true);
                    return;
                  }
                  setData96(defaultdata[0]);
                  setEditButton96(false);
                  setIcon96(<EventAvailable />);
                  setStatus96("ว่างงง");
                  setCancel96(true);
                  setCfer96(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData96(res.data[0]);
                  setEditButton96(true);
                  setIcon96(<EventBusy />);
                  setStatus96("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer96(true);
                      setCancel96(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer96(true);
                      setCancel96(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer96(false);
                      setCancel96(false);
                      return;
                    } else {
                      setCfer96(false);
                      setCancel96(false);
                      return;
                    }
                  } else {
                    setCfer96(true);
                    setCancel96(false);
                    return;
                  }
                }
              } else {
                setData96(defaultdata[2]);
                setEditButton96(true);
                setIcon96(<EventBusy />);
                setStatus96("ไม่ว่าง");
                setCfer96(true);
                setCancel96(true);
                return;
              }
            });
            break;
          case "101":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData101(defaultdata[0]);
                  setEditButton101(false);
                  setIcon101(<EventAvailable />);
                  setStatus101("ว่างงง");
                  setCancel101(true);
                  setCfer101(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData101(res.data[0]);
                  setEditButton101(true);
                  setIcon101(<EventBusy />);
                  setStatus101("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer101(true);
                      setCancel101(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer101(true);
                      setCancel101(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer101(false);
                      setCancel101(false);
                      return;
                    } else {
                      setCfer101(false);
                      setCancel101(false);
                      return;
                    }
                  } else {
                    setCfer101(true);
                    setCancel101(false);
                    return;
                  }
                }
              } else {
                setData101(defaultdata[2]);
                setEditButton101(true);
                setIcon101(<EventBusy />);
                setStatus101("ไม่ว่าง");
                setCfer101(true);
                setCancel101(true);
                return;
              }
            });
            break;
          case "102":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData102(defaultdata[0]);
                  setEditButton102(false);
                  setIcon102(<EventAvailable />);
                  setStatus102("ว่างงง");
                  setCancel102(true);
                  setCfer102(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData102(res.data[0]);
                  setEditButton102(true);
                  setIcon102(<EventBusy />);
                  setStatus102("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer102(true);
                      setCancel102(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer102(true);
                      setCancel102(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer102(false);
                      setCancel102(false);
                      return;
                    } else {
                      setCfer102(false);
                      setCancel102(false);
                      return;
                    }
                  } else {
                    setCfer102(true);
                    setCancel102(false);
                    return;
                  }
                }
              } else {
                setData102(defaultdata[2]);
                setEditButton102(true);
                setIcon102(<EventBusy />);
                setStatus102("ไม่ว่าง");
                setCfer102(true);
                setCancel102(true);
                return;
              }
            });
            break;
          case "103":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  // if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                  //   setData103(defaultdata[1]);
                  //   setEditButton103(true);
                  //   setIcon103(<EventBusy />);
                  //   setStatus103("ไม่ว่าง");
                  //   setCancel103(true);
                  //   setCfer103(true);
                  //   return;
                  // }
                  setData103(defaultdata[0]);
                  setEditButton103(false);
                  setIcon103(<EventAvailable />);
                  setStatus103("ว่างงง");
                  setCancel103(true);
                  setCfer103(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData103(res.data[0]);
                  setEditButton103(true);
                  setIcon103(<EventBusy />);
                  setStatus103("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer103(true);
                      setCancel103(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer103(true);
                      setCancel103(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer103(false);
                      setCancel103(false);
                      return;
                    } else {
                      setCfer103(false);
                      setCancel103(false);
                      return;
                    }
                  } else {
                    setCfer103(true);
                    setCancel103(false);
                    return;
                  }
                }
              } else {
                setData103(defaultdata[2]);
                setEditButton103(true);
                setIcon103(<EventBusy />);
                setStatus103("ไม่ว่าง");
                setCfer103(true);
                setCancel103(true);
                return;
              }
            });
            break;
          case "104":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData104(defaultdata[1]);
                    setEditButton104(true);
                    setIcon104(<EventBusy />);
                    setStatus104("ไม่ว่าง");
                    setCancel104(true);
                    setCfer104(true);
                    return;
                  }
                  setData104(defaultdata[0]);
                  setEditButton104(false);
                  setIcon104(<EventAvailable />);
                  setStatus104("ว่างงง");
                  setCancel104(true);
                  setCfer104(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData104(res.data[0]);
                  setEditButton104(true);
                  setIcon104(<EventBusy />);
                  setStatus104("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer104(true);
                      setCancel104(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer104(true);
                      setCancel104(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer104(false);
                      setCancel104(false);
                      return;
                    } else {
                      setCfer104(false);
                      setCancel104(false);
                      return;
                    }
                  } else {
                    setCfer104(true);
                    setCancel104(false);
                    return;
                  }
                }
              } else {
                setData104(defaultdata[2]);
                setEditButton104(true);
                setIcon104(<EventBusy />);
                setStatus104("ไม่ว่าง");
                setCfer104(true);
                setCancel104(true);
                return;
              }
            });
            break;
          case "105":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData105(defaultdata[1]);
                    setEditButton105(true);
                    setIcon105(<EventBusy />);
                    setStatus105("ไม่ว่าง");
                    setCancel105(true);
                    setCfer105(true);
                    return;
                  }
                  setData105(defaultdata[0]);
                  setEditButton105(false);
                  setIcon105(<EventAvailable />);
                  setStatus105("ว่างงง");
                  setCancel105(true);
                  setCfer105(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData105(res.data[0]);
                  setEditButton105(true);
                  setIcon105(<EventBusy />);
                  setStatus105("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer105(true);
                      setCancel105(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer105(true);
                      setCancel105(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer105(false);
                      setCancel105(false);
                      return;
                    } else {
                      setCfer105(false);
                      setCancel105(false);
                      return;
                    }
                  } else {
                    setCfer105(true);
                    setCancel105(false);
                    return;
                  }
                }
              } else {
                setData105(defaultdata[2]);
                setEditButton105(true);
                setIcon105(<EventBusy />);
                setStatus105("ไม่ว่าง");
                setCfer105(true);
                setCancel105(true);
                return;
              }
            });
            break;
          case "106":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData106(defaultdata[1]);
                    setEditButton106(true);
                    setIcon106(<EventBusy />);
                    setStatus106("ไม่ว่าง");
                    setCancel106(true);
                    setCfer106(true);
                    return;
                  }
                  setData106(defaultdata[0]);
                  setEditButton106(false);
                  setIcon106(<EventAvailable />);
                  setStatus106("ว่างงง");
                  setCancel106(true);
                  setCfer106(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData106(res.data[0]);
                  setEditButton106(true);
                  setIcon106(<EventBusy />);
                  setStatus106("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer106(true);
                      setCancel106(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer106(true);
                      setCancel106(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer106(false);
                      setCancel106(false);
                      return;
                    } else {
                      setCfer106(false);
                      setCancel106(false);
                      return;
                    }
                  } else {
                    setCfer106(true);
                    setCancel106(false);
                    return;
                  }
                }
              } else {
                setData106(defaultdata[2]);
                setEditButton106(true);
                setIcon106(<EventBusy />);
                setStatus106("ไม่ว่าง");
                setCfer106(true);
                setCancel106(true);
                return;
              }
            });
            break;
          case "111":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData111(defaultdata[0]);
                  setEditButton111(false);
                  setIcon111(<EventAvailable />);
                  setStatus111("ว่างงง");
                  setCancel111(true);
                  setCfer111(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData111(res.data[0]);
                  setEditButton111(true);
                  setIcon111(<EventBusy />);
                  setStatus111("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer111(true);
                      setCancel111(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer111(true);
                      setCancel111(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer111(false);
                      setCancel111(false);
                      return;
                    } else {
                      setCfer111(false);
                      setCancel111(false);
                      return;
                    }
                  } else {
                    setCfer111(true);
                    setCancel111(false);
                    return;
                  }
                }
              } else {
                setData111(defaultdata[2]);
                setEditButton111(true);
                setIcon111(<EventBusy />);
                setStatus111("ไม่ว่าง");
                setCfer111(true);
                setCancel111(true);
                return;
              }
            });
            break;
          case "112":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData112(defaultdata[0]);
                  setEditButton112(false);
                  setIcon112(<EventAvailable />);
                  setStatus112("ว่างงง");
                  setCancel112(true);
                  setCfer112(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData112(res.data[0]);
                  setEditButton112(true);
                  setIcon112(<EventBusy />);
                  setStatus112("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer112(true);
                      setCancel112(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer112(true);
                      setCancel112(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer112(false);
                      setCancel112(false);
                      return;
                    } else {
                      setCfer112(false);
                      setCancel112(false);
                      return;
                    }
                  } else {
                    setCfer112(true);
                    setCancel112(false);
                    return;
                  }
                }
              } else {
                setData112(defaultdata[2]);
                setEditButton112(true);
                setIcon112(<EventBusy />);
                setStatus112("ไม่ว่าง");
                setCfer112(true);
                setCancel112(true);
                return;
              }
            });
            break;
          case "113":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  // if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                  //   setData113(defaultdata[1]);
                  //   setEditButton113(true);
                  //   setIcon113(<EventBusy />);
                  //   setStatus113("ไม่ว่าง");
                  //   setCancel113(true);
                  //   setCfer113(true);
                  //   return;
                  // }
                  setData113(defaultdata[0]);
                  setEditButton113(false);
                  setIcon113(<EventAvailable />);
                  setStatus113("ว่างงง");
                  setCancel113(true);
                  setCfer113(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData113(res.data[0]);
                  setEditButton113(true);
                  setIcon113(<EventBusy />);
                  setStatus113("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer113(true);
                      setCancel113(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer113(true);
                      setCancel113(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer113(false);
                      setCancel113(false);
                      return;
                    } else {
                      setCfer113(false);
                      setCancel113(false);
                      return;
                    }
                  } else {
                    setCfer113(true);
                    setCancel113(false);
                    return;
                  }
                }
              } else {
                setData113(defaultdata[2]);
                setEditButton113(true);
                setIcon113(<EventBusy />);
                setStatus113("ไม่ว่าง");
                setCfer113(true);
                setCancel113(true);
                return;
              }
            });
            break;
          case "114":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData114(defaultdata[1]);
                    setEditButton114(true);
                    setIcon114(<EventBusy />);
                    setStatus114("ไม่ว่าง");
                    setCancel114(true);
                    setCfer114(true);
                    return;
                  }
                  setData114(defaultdata[0]);
                  setEditButton114(false);
                  setIcon114(<EventAvailable />);
                  setStatus114("ว่างงง");
                  setCancel114(true);
                  setCfer114(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData114(res.data[0]);
                  setEditButton114(true);
                  setIcon114(<EventBusy />);
                  setStatus114("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer114(true);
                      setCancel114(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer114(true);
                      setCancel114(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer114(false);
                      setCancel114(false);
                      return;
                    } else {
                      setCfer114(false);
                      setCancel114(false);
                      return;
                    }
                  } else {
                    setCfer114(true);
                    setCancel114(false);
                    return;
                  }
                }
              } else {
                setData114(defaultdata[2]);
                setEditButton114(true);
                setIcon114(<EventBusy />);
                setStatus114("ไม่ว่าง");
                setCfer114(true);
                setCancel114(true);
                return;
              }
            });
            break;
          case "115":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData115(defaultdata[1]);
                    setEditButton115(true);
                    setIcon115(<EventBusy />);
                    setStatus115("ไม่ว่าง");
                    setCancel115(true);
                    setCfer115(true);
                    return;
                  }
                  setData115(defaultdata[0]);
                  setEditButton115(false);
                  setIcon115(<EventAvailable />);
                  setStatus115("ว่างงง");
                  setCancel115(true);
                  setCfer115(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData115(res.data[0]);
                  setEditButton115(true);
                  setIcon115(<EventBusy />);
                  setStatus115("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer115(true);
                      setCancel115(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer115(true);
                      setCancel115(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer115(false);
                      setCancel115(false);
                      return;
                    } else {
                      setCfer115(false);
                      setCancel115(false);
                      return;
                    }
                  } else {
                    setCfer115(true);
                    setCancel115(false);
                    return;
                  }
                }
              } else {
                setData115(defaultdata[2]);
                setEditButton115(true);
                setIcon115(<EventBusy />);
                setStatus115("ไม่ว่าง");
                setCfer115(true);
                setCancel115(true);
                return;
              }
            });
            break;
          case "116":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData116(defaultdata[1]);
                    setEditButton116(true);
                    setIcon116(<EventBusy />);
                    setStatus116("ไม่ว่าง");
                    setCancel116(true);
                    setCfer116(true);
                    return;
                  }
                  setData116(defaultdata[0]);
                  setEditButton116(false);
                  setIcon116(<EventAvailable />);
                  setStatus116("ว่างงง");
                  setCancel116(true);
                  setCfer116(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData116(res.data[0]);
                  setEditButton116(true);
                  setIcon116(<EventBusy />);
                  setStatus116("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer116(true);
                      setCancel116(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer116(true);
                      setCancel116(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer116(false);
                      setCancel116(false);
                      return;
                    } else {
                      setCfer116(false);
                      setCancel116(false);
                      return;
                    }
                  } else {
                    setCfer116(true);
                    setCancel116(false);
                    return;
                  }
                }
              } else {
                setData116(defaultdata[2]);
                setEditButton116(true);
                setIcon116(<EventBusy />);
                setStatus116("ไม่ว่าง");
                setCfer116(true);
                setCancel116(true);
                return;
              }
            });
            break;
          case "131":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData131(defaultdata[0]);
                  setEditButton131(false);
                  setIcon131(<EventAvailable />);
                  setStatus131("ว่างงง");
                  setCancel131(true);
                  setCfer131(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData131(res.data[0]);
                  setEditButton131(true);
                  setIcon131(<EventBusy />);
                  setStatus131("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer131(true);
                      setCancel131(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer131(true);
                      setCancel131(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer131(false);
                      setCancel131(false);
                      return;
                    } else {
                      setCfer131(false);
                      setCancel131(false);
                      return;
                    }
                  } else {
                    setCfer131(true);
                    setCancel131(false);
                    return;
                  }
                }
              } else {
                setData131(defaultdata[2]);
                setEditButton131(true);
                setIcon131(<EventBusy />);
                setStatus131("ไม่ว่าง");
                setCfer131(true);
                setCancel131(true);
                return;
              }
            });
            break;
          case "132":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  setData132(defaultdata[0]);
                  setEditButton132(false);
                  setIcon132(<EventAvailable />);
                  setStatus132("ว่างงง");
                  setCancel132(true);
                  setCfer132(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData132(res.data[0]);
                  setEditButton132(true);
                  setIcon132(<EventBusy />);
                  setStatus132("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer132(true);
                      setCancel132(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer132(true);
                      setCancel132(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer132(false);
                      setCancel132(false);
                      return;
                    } else {
                      setCfer132(false);
                      setCancel132(false);
                      return;
                    }
                  } else {
                    setCfer132(true);
                    setCancel132(false);
                    return;
                  }
                }
              } else {
                setData132(defaultdata[2]);
                setEditButton132(true);
                setIcon132(<EventBusy />);
                setStatus132("ไม่ว่าง");
                setCfer132(true);
                setCancel132(true);
                return;
              }
            });
            break;
          case "133":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  // if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                  //   setData133(defaultdata[1]);
                  //   setEditButton133(true);
                  //   setIcon133(<EventBusy />);
                  //   setStatus133("ไม่ว่าง");
                  //   setCancel133(true);
                  //   setCfer133(true);
                  //   return;
                  // }
                  setData133(defaultdata[0]);
                  setEditButton133(false);
                  setIcon133(<EventAvailable />);
                  setStatus133("ว่างงง");
                  setCancel133(true);
                  setCfer133(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData133(res.data[0]);
                  setEditButton133(true);
                  setIcon133(<EventBusy />);
                  setStatus133("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer133(true);
                      setCancel133(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer133(true);
                      setCancel133(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer133(false);
                      setCancel133(false);
                      return;
                    } else {
                      setCfer133(false);
                      setCancel133(false);
                      return;
                    }
                  } else {
                    setCfer133(true);
                    setCancel133(false);
                    return;
                  }
                }
              } else {
                setData133(defaultdata[2]);
                setEditButton133(true);
                setIcon133(<EventBusy />);
                setStatus133("ไม่ว่าง");
                setCfer133(true);
                setCancel133(true);
                return;
              }
            });
            break;
          case "134":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData134(defaultdata[1]);
                    setEditButton134(true);
                    setIcon134(<EventBusy />);
                    setStatus134("ไม่ว่าง");
                    setCancel134(true);
                    setCfer134(true);
                    return;
                  }
                  setData134(defaultdata[0]);
                  setEditButton134(false);
                  setIcon134(<EventAvailable />);
                  setStatus134("ว่างงง");
                  setCancel134(true);
                  setCfer134(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData134(res.data[0]);
                  setEditButton134(true);
                  setIcon134(<EventBusy />);
                  setStatus134("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer134(true);
                      setCancel134(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer134(true);
                      setCancel134(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer134(false);
                      setCancel134(false);
                      return;
                    } else {
                      setCfer134(false);
                      setCancel134(false);
                      return;
                    }
                  } else {
                    setCfer134(true);
                    setCancel134(false);
                    return;
                  }
                }
              } else {
                setData134(defaultdata[2]);
                setEditButton134(true);
                setIcon134(<EventBusy />);
                setStatus134("ไม่ว่าง");
                setCfer134(true);
                setCancel134(true);
                return;
              }
            });
            break;
          case "135":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData135(defaultdata[1]);
                    setEditButton135(true);
                    setIcon135(<EventBusy />);
                    setStatus135("ไม่ว่าง");
                    setCancel135(true);
                    setCfer135(true);
                    return;
                  }
                  setData135(defaultdata[0]);
                  setEditButton135(false);
                  setIcon135(<EventAvailable />);
                  setStatus135("ว่างงง");
                  setCancel135(true);
                  setCfer135(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData135(res.data[0]);
                  setEditButton135(true);
                  setIcon135(<EventBusy />);
                  setStatus135("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer135(true);
                      setCancel135(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer135(true);
                      setCancel135(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer135(false);
                      setCancel135(false);
                      return;
                    } else {
                      setCfer135(false);
                      setCancel135(false);
                      return;
                    }
                  } else {
                    setCfer135(true);
                    setCancel135(false);
                    return;
                  }
                }
              } else {
                setData135(defaultdata[2]);
                setEditButton135(true);
                setIcon135(<EventBusy />);
                setStatus135("ไม่ว่าง");
                setCfer135(true);
                setCancel135(true);
                return;
              }
            });
            break;
          case "136":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData136(defaultdata[1]);
                    setEditButton136(true);
                    setIcon136(<EventBusy />);
                    setStatus136("ไม่ว่าง");
                    setCancel136(true);
                    setCfer136(true);
                    return;
                  }
                  setData136(defaultdata[0]);
                  setEditButton136(false);
                  setIcon136(<EventAvailable />);
                  setStatus136("ว่างงง");
                  setCancel136(true);
                  setCfer136(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData136(res.data[0]);
                  setEditButton136(true);
                  setIcon136(<EventBusy />);
                  setStatus136("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer136(true);
                      setCancel136(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer136(true);
                      setCancel136(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer136(false);
                      setCancel136(false);
                      return;
                    } else {
                      setCfer136(false);
                      setCancel136(false);
                      return;
                    }
                  } else {
                    setCfer136(true);
                    setCancel136(false);
                    return;
                  }
                }
              } else {
                setData136(defaultdata[2]);
                setEditButton136(true);
                setIcon136(<EventBusy />);
                setStatus136("ไม่ว่าง");
                setCfer136(true);
                setCancel136(true);
                return;
              }
            });
            break;
          case "141":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData141(defaultdata[1]);
                    setEditButton141(true);
                    setIcon141(<EventBusy />);
                    setStatus141("ไม่ว่าง");
                    setCancel141(true);
                    setCfer141(true);
                    return;
                  }
                  setData141(defaultdata[0]);
                  setEditButton141(false);
                  setIcon141(<EventAvailable />);
                  setStatus141("ว่างงง");
                  setCancel141(true);
                  setCfer141(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData141(res.data[0]);
                  setEditButton141(true);
                  setIcon141(<EventBusy />);
                  setStatus141("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer141(true);
                      setCancel141(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer141(true);
                      setCancel141(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer141(false);
                      setCancel141(false);
                      return;
                    } else {
                      setCfer141(false);
                      setCancel141(false);
                      return;
                    }
                  } else {
                    setCfer141(true);
                    setCancel141(false);
                    return;
                  }
                }
              } else {
                setData141(defaultdata[2]);
                setEditButton141(true);
                setIcon141(<EventBusy />);
                setStatus141("ไม่ว่าง");
                setCfer141(true);
                setCancel141(true);
                return;
              }
            });
            break;
          case "142":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData142(defaultdata[1]);
                    setEditButton142(true);
                    setIcon142(<EventBusy />);
                    setStatus142("ไม่ว่าง");
                    setCancel142(true);
                    setCfer142(true);
                    return;
                  }
                  setData142(defaultdata[0]);
                  setEditButton142(false);
                  setIcon142(<EventAvailable />);
                  setStatus142("ว่างงง");
                  setCancel142(true);
                  setCfer142(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData142(res.data[0]);
                  setEditButton142(true);
                  setIcon142(<EventBusy />);
                  setStatus142("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer142(true);
                      setCancel142(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer142(true);
                      setCancel142(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer142(false);
                      setCancel142(false);
                      return;
                    } else {
                      setCfer142(false);
                      setCancel142(false);
                      return;
                    }
                  } else {
                    setCfer142(true);
                    setCancel142(false);
                    return;
                  }
                }
              } else {
                setData142(defaultdata[2]);
                setEditButton142(true);
                setIcon142(<EventBusy />);
                setStatus142("ไม่ว่าง");
                setCfer142(true);
                setCancel142(true);
                return;
              }
            });
            break;
          case "143":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData143(defaultdata[1]);
                    setEditButton143(true);
                    setIcon143(<EventBusy />);
                    setStatus143("ไม่ว่าง");
                    setCancel143(true);
                    setCfer143(true);
                    return;
                  }
                  setData143(defaultdata[0]);
                  setEditButton143(false);
                  setIcon143(<EventAvailable />);
                  setStatus143("ว่างงง");
                  setCancel143(true);
                  setCfer143(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData143(res.data[0]);
                  setEditButton143(true);
                  setIcon143(<EventBusy />);
                  setStatus143("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer143(true);
                      setCancel143(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer143(true);
                      setCancel143(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer143(false);
                      setCancel143(false);
                      return;
                    } else {
                      setCfer143(false);
                      setCancel143(false);
                      return;
                    }
                  } else {
                    setCfer143(true);
                    setCancel143(false);
                    return;
                  }
                }
              } else {
                setData143(defaultdata[2]);
                setEditButton143(true);
                setIcon143(<EventBusy />);
                setStatus143("ไม่ว่าง");
                setCfer143(true);
                setCancel143(true);
                return;
              }
            });
            break;
          case "144":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData144(defaultdata[1]);
                    setEditButton144(true);
                    setIcon144(<EventBusy />);
                    setStatus144("ไม่ว่าง");
                    setCancel144(true);
                    setCfer144(true);
                    return;
                  }
                  setData144(defaultdata[0]);
                  setEditButton144(false);
                  setIcon144(<EventAvailable />);
                  setStatus144("ว่างงง");
                  setCancel144(true);
                  setCfer144(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData144(res.data[0]);
                  setEditButton144(true);
                  setIcon144(<EventBusy />);
                  setStatus144("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer144(true);
                      setCancel144(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer144(true);
                      setCancel144(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer144(false);
                      setCancel144(false);
                      return;
                    } else {
                      setCfer144(false);
                      setCancel144(false);
                      return;
                    }
                  } else {
                    setCfer144(true);
                    setCancel144(false);
                    return;
                  }
                }
              } else {
                setData144(defaultdata[2]);
                setEditButton144(true);
                setIcon144(<EventBusy />);
                setStatus144("ไม่ว่าง");
                setCfer144(true);
                setCancel144(true);
                return;
              }
            });
            break;
          case "145":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData145(defaultdata[1]);
                    setEditButton145(true);
                    setIcon145(<EventBusy />);
                    setStatus145("ไม่ว่าง");
                    setCancel145(true);
                    setCfer145(true);
                    return;
                  }
                  setData145(defaultdata[0]);
                  setEditButton145(false);
                  setIcon145(<EventAvailable />);
                  setStatus145("ว่างงง");
                  setCancel145(true);
                  setCfer145(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData145(res.data[0]);
                  setEditButton145(true);
                  setIcon145(<EventBusy />);
                  setStatus145("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer145(true);
                      setCancel145(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer145(true);
                      setCancel145(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer145(false);
                      setCancel145(false);
                      return;
                    } else {
                      setCfer145(false);
                      setCancel145(false);
                      return;
                    }
                  } else {
                    setCfer145(true);
                    setCancel145(false);
                    return;
                  }
                }
              } else {
                setData145(defaultdata[2]);
                setEditButton145(true);
                setIcon145(<EventBusy />);
                setStatus145("ไม่ว่าง");
                setCfer145(true);
                setCancel145(true);
                return;
              }
            });
            break;
          case "146":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if ((startDate.getDay() === 0 || startDate.getDay() === 6) && roletoken === "admin") {
                    setData146(defaultdata[1]);
                    setEditButton146(true);
                    setIcon146(<EventBusy />);
                    setStatus146("ไม่ว่าง");
                    setCancel146(true);
                    setCfer146(true);
                    return;
                  }
                  setData146(defaultdata[3]);
                  setEditButton146(false);
                  setIcon146(<EventAvailable />);
                  setStatus146("ว่างงง");
                  setCancel146(true);
                  setCfer146(true);
                  return;
                } else if (res.data.length >= 1) {
                  setData146(res.data[0]);
                  setEditButton146(true);
                  setIcon146(<EventBusy />);
                  setStatus146("ไม่ว่าง");
                  if (dateInPast(startDate, new Date())) {
                    if (TN[0] === 1 || TN[0] === 2) {
                      setCfer146(true);
                      setCancel146(true);
                      return;
                    } else if (TN[0] > 2 && TN[0] < 10) {
                      setCfer146(true);
                      setCancel146(false);
                      return;
                    } else if (TN[0] > 10) {
                      setCfer146(false);
                      setCancel146(false);
                      return;
                    } else {
                      setCfer146(false);
                      setCancel146(false);
                      return;
                    }
                  } else {
                    setCfer146(true);
                    setCancel146(false);
                    return;
                  }
                }
              } else {
                setData146(defaultdata[2]);
                setEditButton146(true);
                setIcon146(<EventBusy />);
                setStatus146("ไม่ว่าง");
                setCfer146(true);
                setCancel146(true);
                return;
              }
            });
            break;
          default:
            console.log("fail");
        }
      };

      setdata(
        res91,
        "91",
        "08:50",
        res91.data.map((item) => item.TN)
      );
      setdata(
        res92,
        "92",
        "08:50",
        res92.data.map((item) => item.TN)
      );
      setdata(
        res93,
        "93",
        "08:50",
        res93.data.map((item) => item.TN)
      );
      setdata(
        res94,
        "94",
        "08:50",
        res94.data.map((item) => item.TN)
      );
      setdata(
        res95,
        "95",
        "08:50",
        res95.data.map((item) => item.TN)
      );
      setdata(
        res96,
        "96",
        "08:50",
        res96.data.map((item) => item.TN)
      );
      setdata(
        res101,
        "101",
        "09:55",
        res101.data.map((item) => item.TN)
      );
      setdata(
        res102,
        "102",
        "09:55",
        res102.data.map((item) => item.TN)
      );
      setdata(
        res103,
        "103",
        "09:55",
        res103.data.map((item) => item.TN)
      );
      setdata(
        res104,
        "104",
        "09:55",
        res104.data.map((item) => item.TN)
      );
      setdata(
        res105,
        "105",
        "09:55",
        res105.data.map((item) => item.TN)
      );
      setdata(
        res106,
        "106",
        "09:55",
        res106.data.map((item) => item.TN)
      );
      setdata(
        res111,
        "111",
        "11:00",
        res111.data.map((item) => item.TN)
      );
      setdata(
        res112,
        "112",
        "11:00",
        res112.data.map((item) => item.TN)
      );
      setdata(
        res113,
        "113",
        "11:00",
        res113.data.map((item) => item.TN)
      );
      setdata(
        res114,
        "114",
        "11:00",
        res114.data.map((item) => item.TN)
      );
      setdata(
        res115,
        "115",
        "11:00",
        res115.data.map((item) => item.TN)
      );
      setdata(
        res116,
        "116",
        "11:00",
        res116.data.map((item) => item.TN)
      );
      setdata(
        res131,
        "131",
        "13:30",
        res131.data.map((item) => item.TN)
      );
      setdata(
        res132,
        "132",
        "13:30",
        res132.data.map((item) => item.TN)
      );
      setdata(
        res133,
        "133",
        "13:30",
        res133.data.map((item) => item.TN)
      );
      setdata(
        res134,
        "134",
        "13:30",
        res134.data.map((item) => item.TN)
      );
      setdata(
        res135,
        "135",
        "13:30",
        res135.data.map((item) => item.TN)
      );
      setdata(
        res136,
        "136",
        "13:30",
        res136.data.map((item) => item.TN)
      );
      setdata(
        res141,
        "141",
        "14:45",
        res141.data.map((item) => item.TN)
      );
      setdata(
        res142,
        "142",
        "14:45",
        res142.data.map((item) => item.TN)
      );
      setdata(
        res143,
        "143",
        "14:45",
        res143.data.map((item) => item.TN)
      );
      setdata(
        res144,
        "144",
        "14:45",
        res144.data.map((item) => item.TN)
      );
      setdata(
        res145,
        "145",
        "14:45",
        res145.data.map((item) => item.TN)
      );
      setdata(
        res146,
        "146",
        "14:45",
        res146.data.map((item) => item.TN)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, [startDate, reData91, reData92, reData93, reData94, reData95, reData96, reData101, reData102, reData103, reData104, reData105, reData106, reData111, reData112, reData113, reData114, reData115, reData116, reData131, reData132, reData133, reData134, reData135, reData136, reData141, reData142, reData143, reData144, reData145, reData146, URL]);

  //api

  //handle
  const handleSubmit = (appurl, appid, timestring, timeid) => {
    const PostURL = `${URL}/${appurl}/${timeid}/${selectdate}`;
    axios.get(PostURL).then((res) => {
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen(false);
            recheck(appid);
          }
        });
        return;
      } else if (!service || !selecttn) {
        Swal.fire({
          icon: "warning",
          title: "คำเตือน",
          text: "กรุณากรอก TN หรือ หัตถการให้ถูกต้อง",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      Swal.fire({
        title: "ยืนยันการนัดหมาย",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          addholiday(appurl, appid);
          addappoinment(appurl, appid, timestring, timeid);
        }
      });
    });
  };
  const handleConfirm = (TN, AN, date, time, provider, appurl, appid, appconfirm, timeid) => {
    Swal.fire({
      title: "ยืนยันการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addconfirmlist(TN, AN, date, time, provider, appurl, appid, appconfirm, timeid);
      }
    });
  };
  const handleCancel = (TN, AN, appurl, appid, appcancel, timestring, timeid) => {
    Swal.fire({
      title: "ยกเลิกการเข้ารับบริการ",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteholiday(TN);
        canceldata(AN, appurl, appid, appcancel, timestring, timeid);
      }
    });
  };
  const handleBlock = (TN, AN, date, time, provider, appurl, appid, appblock, appconfirm, timeid) => {
    Swal.fire({
      title: "ยืนยันผู้รับบริการผิดนัด",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ปิด",
    }).then((result) => {
      if (result.isConfirmed) {
        addblocklist(TN, AN, date, time, provider, appurl, appid, appblock, appconfirm, timeid);
      }
    });
  };
  //handle

  //modal
  const [selecttn, setSelecttn] = useState("");
  const [service, setService] = useState("");
  const [provider, setProvider] = useState("หญิง");
  const [selectname, setSelectname] = useState("");
  const [selectdate, setSelectdate] = useState(`${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`);
  const selectpatient = (TN, prefix, fname, lname) => {
    setSelecttn(TN);
    setSelectname(`${prefix}${fname} ${lname}`);
  };
  const [open, setOpen] = useState(false);
  const openmadal = () => {
    setOpen(true);
    return;
  };
  const closemadal = () => {
    setSelecttn("");
    setSelectname("");
    setOpen(false);
    return;
  };
  //modal

  //cidmodal

  const [opencid, setOpencid] = useState(false);
  const setdata = (data) => {
    return;
  };
  //cidmodal
  return (
    <div>
      <Job startDate={startDate} />
      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "10px 10px 10px 10px" }}>
          <DatePicker
            onChange={(Date) => {
              setStartDate(Date);
              setSelectdate(`${Date.getDate()}-${Date.getMonth() + 1}-${Date.getFullYear()}`);
              return;
            }}
            value={startDate}
            format="dd-MM-y"
            locale="th-TH"
            calendarClassName="calendarclass"
            className="picker"
          />
          <span style={{ marginLeft: "10px", fontSize: "small" }}>{dayname}</span>
        </h2>
      </div>
      <div className="testtest">
        <table className="ondate">
          <tbody>
            {/* หัวข้อ */}
            <tr className="head">
              <th className="headtime">เวลา</th>
              <th className="headname">
                ชื่อ-สกุล
                <Button color="secondary" size="small" onClick={() => setOpencid(true)}>
                  <Search size="small" />
                </Button>
                <Modal open={opencid} onClose={() => setOpencid(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Box sx={styleee}>
                    <div className="flex justify-center items-center">
                      <div className="m-2">
                        <h6 className="text-center">ค้นหาจากฐาน HOSXP</h6>
                        <Searchrole setdata={setdata} />
                      </div>
                      <div className="m-2">
                        <h6 className="text-center">ค้นหาจากฐาน THAIPADI</h6>
                        <Searchtel setdata={setdata} />
                      </div>
                    </div>
                  </Box>
                </Modal>
              </th>
              <th className="headservice">หัตถการ</th>
              <th className="headprovider">ผู้ให้บริการ</th>
              <th className="headstatus">
                สถานะ
                <Button color="warning" size="small" onClick={linealert}>
                  <NotificationAdd size="small" />
                </Button>
              </th>
            </tr>
            {/* หัวข้อ */}
            {/* 08:50 */}
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data91.prefix}
                {data91.fname} {data91.lname}
              </td>
              <td className="bodyservice">{data91.service}</td>
              <td className="bodyprovider">{data91.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon91}
                  disabled={editButton91}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("91");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status91}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer91}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data91.TN, data91.AN, data91.date, data91.time, data91.provider, "appoinment", "91", "confirmappoinment", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel91}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data91.TN, data91.AN, "appoinment", "91", "clearwomen", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer91}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data91.TN, data91.AN, data91.date, data91.time, data91.provider, "appoinment", "91", "blocklist", "confirmappoinment", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data92.prefix}
                {data92.fname} {data92.lname}
              </td>
              <td className="bodyservice">{data92.service}</td>
              <td className="bodyprovider">{data92.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon92}
                  disabled={editButton92}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("92");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status92}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer92}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data92.TN, data92.AN, data92.date, data92.time, data92.provider, "appoinments", "92", "confirmappoinments", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel92}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data92.TN, data92.AN, "appoinments", "92", "clearwomens", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer92}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data92.TN, data92.AN, data92.date, data92.time, data92.provider, "appoinments", "92", "blocklist", "confirmappoinments", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data93.prefix}
                {data93.fname} {data93.lname}
              </td>
              <td className="bodyservice">{data93.service}</td>
              <td className="bodyprovider">{data93.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon93}
                  disabled={editButton93}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("93");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status93}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer93}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data93.TN, data93.AN, data93.date, data93.time, data93.provider, "appoinmentss", "93", "confirmappoinmentss", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel93}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data93.TN, data93.AN, "appoinmentss", "93", "clearwomenss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer93}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data93.TN, data93.AN, data93.date, data93.time, data93.provider, "appoinmentss", "93", "blocklist", "confirmappoinmentss", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data94.prefix}
                {data94.fname} {data94.lname}
              </td>
              <td className="bodyservice">{data94.service}</td>
              <td className="bodyprovider">{data94.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon94}
                  disabled={editButton94}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("94");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status94}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer94}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data94.TN, data94.AN, data94.date, data94.time, data94.provider, "appoinmentsss", "94", "confirmappoinmentsss", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel94}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data94.TN, data94.AN, "appoinmentsss", "94", "clearwomensss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer94}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data94.TN, data94.AN, data94.date, data94.time, data94.provider, "appoinmentsss", "94", "blocklist", "confirmappoinmentsss", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data95.prefix}
                {data95.fname} {data95.lname}
              </td>
              <td className="bodyservice">{data95.service}</td>
              <td className="bodyprovider">{data95.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon95}
                  disabled={editButton95}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("95");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status95}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer95}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data95.TN, data95.AN, data95.date, data95.time, data95.provider, "appoinmentssss", "95", "confirmappoinmentssss", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel95}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data95.TN, data95.AN, "appoinmentssss", "95", "clearwomenssss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer95}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data95.TN, data95.AN, data95.date, data95.time, data95.provider, "appoinmentssss", "95", "blocklist", "confirmappoinmentssss", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodynine" style={{ display:'none'}}>
              <td className="bodytime">08:50 น.</td>
              <td className="bodyname">
                {data96.prefix}
                {data96.fname} {data96.lname}
              </td>
              <td className="bodyservice">{data96.service}</td>
              <td className="bodyprovider">{data96.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon96}
                  disabled={editButton96}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("96");
                    setTimeString(time9[0]);
                    setTimeId(time9[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status96}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer96}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data96.TN, data96.AN, data96.date, data96.time, data96.provider, "appoinmentsssss", "96", "confirmappoinmentsssss", time9[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel96}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data96.TN, data96.AN, "appoinmentsssss", "96", "clearwomensssss", time9[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer96}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data96.TN, data96.AN, data96.date, data96.time, data96.provider, "appoinmentsssss", "96", "blocklist", "confirmappoinmentsssss", time9[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            {/* 08:50 */}
            {/* 09:55 */}
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data101.prefix}
                {data101.fname} {data101.lname}
              </td>
              <td className="bodyservice">{data101.service}</td>
              <td className="bodyprovider">{data101.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon101}
                  disabled={editButton101}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("101");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status101}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer101}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data101.TN, data101.AN, data101.date, data101.time, data101.provider, "appoinment", "101", "confirmappoinment", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel101}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data101.TN, data101.AN, "appoinment", "101", "clearwomen", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer101}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data101.TN, data101.AN, data101.date, data101.time, data101.provider, "appoinment", "101", "blocklist", "confirmappoinment", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data102.prefix}
                {data102.fname} {data102.lname}
              </td>
              <td className="bodyservice">{data102.service}</td>
              <td className="bodyprovider">{data102.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon102}
                  disabled={editButton102}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("102");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status102}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer102}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data102.TN, data102.AN, data102.date, data102.time, data102.provider, "appoinments", "102", "confirmappoinments", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel102}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data102.TN, data102.AN, "appoinments", "102", "clearwomens", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer102}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data102.TN, data102.AN, data102.date, data102.time, data102.provider, "appoinments", "102", "blocklist", "confirmappoinments", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data103.prefix}
                {data103.fname} {data103.lname}
              </td>
              <td className="bodyservice">{data103.service}</td>
              <td className="bodyprovider">{data103.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon103}
                  disabled={editButton103}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("103");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status103}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer103}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data103.TN, data103.AN, data103.date, data103.time, data103.provider, "appoinmentss", "103", "confirmappoinmentss", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel103}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data103.TN, data103.AN, "appoinmentss", "103", "clearwomenss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer103}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data103.TN, data103.AN, data103.date, data103.time, data103.provider, "appoinmentss", "103", "blocklist", "confirmappoinmentss", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data104.prefix}
                {data104.fname} {data104.lname}
              </td>
              <td className="bodyservice">{data104.service}</td>
              <td className="bodyprovider">{data104.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon104}
                  disabled={editButton104}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("104");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status104}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer104}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data104.TN, data104.AN, data104.date, data104.time, data104.provider, "appoinmentsss", "104", "confirmappoinmentsss", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel104}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data104.TN, data104.AN, "appoinmentsss", "104", "clearwomensss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer104}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data104.TN, data104.AN, data104.date, data104.time, data104.provider, "appoinmentsss", "104", "blocklist", "confirmappoinmentsss", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data105.prefix}
                {data105.fname} {data105.lname}
              </td>
              <td className="bodyservice">{data105.service}</td>
              <td className="bodyprovider">{data105.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon105}
                  disabled={editButton105}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("105");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status105}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer105}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data105.TN, data105.AN, data105.date, data105.time, data105.provider, "appoinmentssss", "105", "confirmappoinmentssss", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel105}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data105.TN, data105.AN, "appoinmentssss", "105", "clearwomenssss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer105}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data105.TN, data105.AN, data105.date, data105.time, data105.provider, "appoinmentssss", "105", "blocklist", "confirmappoinmentssss", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">09:55 น.</td>
              <td className="bodyname">
                {data106.prefix}
                {data106.fname} {data106.lname}
              </td>
              <td className="bodyservice">{data106.service}</td>
              <td className="bodyprovider">{data106.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon106}
                  disabled={editButton106}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("106");
                    setTimeString(time10[0]);
                    setTimeId(time10[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status106}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer106}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data106.TN, data106.AN, data106.date, data106.time, data106.provider, "appoinmentsssss", "106", "confirmappoinmentsssss", time10[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel106}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data106.TN, data106.AN, "appoinmentsssss", "106", "clearwomensssss", time10[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer106}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data106.TN, data106.AN, data106.date, data106.time, data106.provider, "appoinmentsssss", "106", "blocklist", "confirmappoinmentsssss", time10[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            {/* 09:55 */}
            {/* 11:00 */}
            <tr className="bodyeleven">
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data111.prefix}
                {data111.fname} {data111.lname}
              </td>
              <td className="bodyservice">{data111.service}</td>
              <td className="bodyprovider">{data111.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon111}
                  disabled={editButton111}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("111");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status111}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer111}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data111.TN, data111.AN, data111.date, data111.time, data111.provider, "appoinment", "111", "confirmappoinment", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel111}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data111.TN, data111.AN, "appoinment", "111", "clearwomen", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer111}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data111.TN, data111.AN, data111.date, data111.time, data111.provider, "appoinment", "111", "blocklist", "confirmappoinment", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data112.prefix}
                {data112.fname} {data112.lname}
              </td>
              <td className="bodyservice">{data112.service}</td>
              <td className="bodyprovider">{data112.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon112}
                  disabled={editButton112}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("112");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status112}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer112}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data112.TN, data112.AN, data112.date, data112.time, data112.provider, "appoinments", "112", "confirmappoinments", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel112}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data112.TN, data112.AN, "appoinments", "112", "clearwomens", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer112}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data112.TN, data112.AN, data112.date, data112.time, data112.provider, "appoinments", "112", "blocklist", "confirmappoinments", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data113.prefix}
                {data113.fname} {data113.lname}
              </td>
              <td className="bodyservice">{data113.service}</td>
              <td className="bodyprovider">{data113.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon113}
                  disabled={editButton113}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("113");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status113}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer113}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data113.TN, data113.AN, data113.date, data113.time, data113.provider, "appoinmentss", "113", "confirmappoinmentss", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel113}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data113.TN, data113.AN, "appoinmentss", "113", "clearwomenss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer113}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data113.TN, data113.AN, data113.date, data113.time, data113.provider, "appoinmentss", "113", "blocklist", "confirmappoinmentss", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data114.prefix}
                {data114.fname} {data114.lname}
              </td>
              <td className="bodyservice">{data114.service}</td>
              <td className="bodyprovider">{data114.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon114}
                  disabled={editButton114}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("114");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status114}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer114}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data114.TN, data114.AN, data114.date, data114.time, data114.provider, "appoinmentsss", "114", "confirmappoinmentsss", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel114}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data114.TN, data114.AN, "appoinmentsss", "114", "clearwomensss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer114}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data114.TN, data114.AN, data114.date, data114.time, data114.provider, "appoinmentsss", "114", "blocklist", "confirmappoinmentsss", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven">
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data115.prefix}
                {data115.fname} {data115.lname}
              </td>
              <td className="bodyservice">{data115.service}</td>
              <td className="bodyprovider">{data115.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon115}
                  disabled={editButton115}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("115");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status115}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer115}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data115.TN, data115.AN, data115.date, data115.time, data115.provider, "appoinmentssss", "115", "confirmappoinmentssss", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel115}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data115.TN, data115.AN, "appoinmentssss", "115", "clearwomenssss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer115}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data115.TN, data115.AN, data115.date, data115.time, data115.provider, "appoinmentssss", "115", "blocklist", "confirmappoinmentssss", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="bodyeleven" style={{ display:'none'}}>
              <td className="bodytime">11:00 น.</td>
              <td className="bodyname">
                {data116.prefix}
                {data116.fname} {data116.lname}
              </td>
              <td className="bodyservice">{data116.service}</td>
              <td className="bodyprovider">{data116.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon116}
                  disabled={editButton116}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("116");
                    setTimeString(time11[0]);
                    setTimeId(time11[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status116}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer116}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data116.TN, data116.AN, data116.date, data116.time, data116.provider, "appoinmentsssss", "116", "confirmappoinmentsssss", time11[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel116}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data116.TN, data116.AN, "appoinmentsssss", "116", "clearwomensssss", time11[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer116}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data116.TN, data116.AN, data116.date, data116.time, data116.provider, "appoinmentsssss", "116", "blocklist", "confirmappoinmentsssss", time11[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            {/* 11:00 */}
            {/* 13.30 */}
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data131.prefix}
                {data131.fname} {data131.lname}
              </td>
              <td className="bodyservice">{data131.service}</td>
              <td className="bodyprovider">{data131.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon131}
                  disabled={editButton131}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("131");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status131}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer131}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data131.TN, data131.AN, data131.date, data131.time, data131.provider, "appoinment", "131", "confirmappoinment", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel131}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data131.TN, data131.AN, "appoinment", "131", "clearwomen", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer131}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data131.TN, data131.AN, data131.date, data131.time, data131.provider, "appoinment", "131", "blocklist", "confirmappoinment", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data132.prefix}
                {data132.fname} {data132.lname}
              </td>
              <td className="bodyservice">{data132.service}</td>
              <td className="bodyprovider">{data132.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon132}
                  disabled={editButton132}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("132");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status132}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer132}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data132.TN, data132.AN, data132.date, data132.time, data132.provider, "appoinments", "132", "confirmappoinments", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel132}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data132.TN, data132.AN, "appoinments", "132", "clearwomens", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer132}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data132.TN, data132.AN, data132.date, data132.time, data132.provider, "appoinments", "132", "blocklist", "confirmappoinments", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data133.prefix}
                {data133.fname} {data133.lname}
              </td>
              <td className="bodyservice">{data133.service}</td>
              <td className="bodyprovider">{data133.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon133}
                  disabled={editButton133}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("133");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status133}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer133}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data133.TN, data133.AN, data133.date, data133.time, data133.provider, "appoinmentss", "133", "confirmappoinmentss", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel133}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data133.TN, data133.AN, "appoinmentss", "133", "clearwomenss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer133}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data133.TN, data133.AN, data133.date, data133.time, data133.provider, "appoinmentss", "133", "blocklist", "confirmappoinmentss", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data134.prefix}
                {data134.fname} {data134.lname}
              </td>
              <td className="bodyservice">{data134.service}</td>
              <td className="bodyprovider">{data134.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon134}
                  disabled={editButton134}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("134");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status134}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer134}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data134.TN, data134.AN, data134.date, data134.time, data134.provider, "appoinmentsss", "134", "confirmappoinmentsss", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel134}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data134.TN, data134.AN, "appoinmentsss", "134", "clearwomensss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer134}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data134.TN, data134.AN, data134.date, data134.time, data134.provider, "appoinmentsss", "134", "blocklist", "confirmappoinmentsss", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data135.prefix}
                {data135.fname} {data135.lname}
              </td>
              <td className="bodyservice">{data135.service}</td>
              <td className="bodyprovider">{data135.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon135}
                  disabled={editButton135}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("135");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status135}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer135}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data135.TN, data135.AN, data135.date, data135.time, data135.provider, "appoinmentssss", "135", "confirmappoinmentssss", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel135}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data135.TN, data135.AN, "appoinmentssss", "135", "clearwomenssss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer135}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data135.TN, data135.AN, data135.date, data135.time, data135.provider, "appoinmentssss", "135", "blocklist", "confirmappoinmentssss", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">{time13[0]} น.</td>
              <td className="bodyname">
                {data136.prefix}
                {data136.fname} {data136.lname}
              </td>
              <td className="bodyservice">{data136.service}</td>
              <td className="bodyprovider">ฟาตีเม๊าะ</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon136}
                  disabled={editButton136}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("136");
                    setTimeString(time13[0]);
                    setTimeId(time13[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status136}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer136}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data136.TN, data136.AN, data136.date, data136.time, data136.provider, "appoinmentsssss", "136", "confirmappoinmentsssss", time13[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel136}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data136.TN, data136.AN, "appoinmentsssss", "136", "clearwomensssss", time13[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer136}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data136.TN, data136.AN, data136.date, data136.time, data136.provider, "appoinmentsssss", "136", "blocklist", "confirmappoinmentsssss", time13[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            {/* 13.30 */}
            {/* 14.45 */}
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data141.prefix}
                {data141.fname} {data141.lname}
              </td>
              <td className="bodyservice">{data141.service}</td>
              <td className="bodyprovider">{data141.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon141}
                  disabled={editButton141}
                  onClick={() => {
                    setAppUrl("appoinment");
                    setAppId("141");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status141}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer141}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data141.TN, data141.AN, data141.date, data141.time, data141.provider, "appoinment", "141", "confirmappoinment", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel141}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data141.TN, data141.AN, "appoinment", "141", "clearwomen", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer141}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data141.TN, data141.AN, data141.date, data141.time, data141.provider, "appoinment", "141", "blocklist", "confirmappoinment", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data142.prefix}
                {data142.fname} {data142.lname}
              </td>
              <td className="bodyservice">{data142.service}</td>
              <td className="bodyprovider">{data142.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon142}
                  disabled={editButton142}
                  onClick={() => {
                    setAppUrl("appoinments");
                    setAppId("142");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status142}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer142}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data142.TN, data142.AN, data142.date, data142.time, data142.provider, "appoinments", "142", "confirmappoinments", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel142}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data142.TN, data142.AN, "appoinments", "142", "clearwomens", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer142}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data142.TN, data142.AN, data142.date, data142.time, data142.provider, "appoinments", "142", "blocklist", "confirmappoinments", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data143.prefix}
                {data143.fname} {data143.lname}
              </td>
              <td className="bodyservice">{data143.service}</td>
              <td className="bodyprovider">{data143.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon143}
                  disabled={editButton143}
                  onClick={() => {
                    setAppUrl("appoinmentss");
                    setAppId("143");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status143}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer143}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data143.TN, data143.AN, data143.date, data143.time, data143.provider, "appoinmentss", "143", "confirmappoinmentss", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel143}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data143.TN, data143.AN, "appoinmentss", "143", "clearwomenss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer143}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data143.TN, data143.AN, data143.date, data143.time, data143.provider, "appoinmentss", "143", "blocklist", "confirmappoinmentss", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data144.prefix}
                {data144.fname} {data144.lname}
              </td>
              <td className="bodyservice">{data144.service}</td>
              <td className="bodyprovider">{data144.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon144}
                  disabled={editButton144}
                  onClick={() => {
                    setAppUrl("appoinmentsss");
                    setAppId("144");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status144}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer144}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data144.TN, data144.AN, data144.date, data144.time, data144.provider, "appoinmentsss", "144", "confirmappoinmentsss", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel144}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data144.TN, data144.AN, "appoinmentsss", "144", "clearwomensss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer144}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data144.TN, data144.AN, data144.date, data144.time, data144.provider, "appoinmentsss", "144", "blocklist", "confirmappoinmentsss", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data145.prefix}
                {data145.fname} {data145.lname}
              </td>
              <td className="bodyservice">{data145.service}</td>
              <td className="bodyprovider">{data145.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon145}
                  disabled={editButton145}
                  onClick={() => {
                    setAppUrl("appoinmentssss");
                    setAppId("145");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status145}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer145}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data145.TN, data145.AN, data145.date, data145.time, data145.provider, "appoinmentssss", "145", "confirmappoinmentssss", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel145}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data145.TN, data145.AN, "appoinmentssss", "145", "clearwomenssss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer145}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data145.TN, data145.AN, data145.date, data145.time, data145.provider, "appoinmentssss", "145", "blocklist", "confirmappoinmentssss", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            <tr className="body14" style={{ display:'none'}}>
              <td className="bodytime">{time14[0]} น.</td>
              <td className="bodyname">
                {data146.prefix}
                {data146.fname} {data146.lname}
              </td>
              <td className="bodyservice">{data146.service}</td>
              <td className="bodyprovider">{data146.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className="btnstatus"
                  startIcon={icon146}
                  disabled={editButton146}
                  onClick={() => {
                    setAppUrl("appoinmentsssss");
                    setAppId("146");
                    setTimeString(time14[0]);
                    setTimeId(time14[1]);
                    openmadal();
                  }}
                  style={{ margin: "0 0 0 4px" }}
                >
                  {status146}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="cfer"
                  startIcon={<Send />}
                  disabled={cfer146}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleConfirm(data146.TN, data146.AN, data146.date, data146.time, data146.provider, "appoinmentsssss", "146", "confirmappoinmentsssss", time14[1]);
                    return;
                  }}
                >
                  ยันยัน
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel146}
                  startIcon={<CancelOutlined />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleCancel(data146.TN, data146.AN, "appoinmentsssss", "146", "clearwomensssss", time14[1]);
                    return;
                  }}
                  className="btnstatus"
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="cfer"
                  size="small"
                  disabled={cfer146}
                  startIcon={<Block />}
                  style={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    handleBlock(data146.TN, data146.AN, data146.date, data146.time, data146.provider, "appoinmentsssss", "146", "blocklist", "confirmappoinmentsssss", time14[1]);
                    return;
                  }}
                >
                  ผิดนัด
                </Button>
              </td>
            </tr>
            {/* 14.45 */}
            <Modal open={open} onClose={closemadal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={stylee}>
                <Paper>
                  <Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <TextField fullWidth id="fullWidth" label="ค้นหา..." variant="outlined" onChange={testtt} size="small" />
                    </Box>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "4px",
                    }}
                  >
                    <TableContainer sx={{ height: "160px", maxWidth: "320px" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "30px",
                                maxHeight: "30px",
                                padding: "5px 0 5px 0",
                                backgroundColor: "#1bb3b3",
                              }}
                            >
                              HN
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxHeight: "30px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                            >
                              ชื่อสกุล
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxHeight: "30px",
                                padding: "0 0 0 0",
                                backgroundColor: "#1bb3b3",
                              }}
                            >
                              สิทธิ์
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "25px",
                                padding: "5px 5px 5px 5px",
                                backgroundColor: "#1bb3b3",
                              }}
                            >
                              เลือก
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data
                            .filter((e) => {
                              return e.fname.includes(search) || e.lname.includes(search) || e.TN.toString().includes(search) || e.tel.includes(search) || e.hn.includes(search);
                            })
                            .map((item) => {
                              return (
                                <TableRow key={item.TN}>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontSize: "12px",
                                      maxWidth: "30px",
                                      padding: "5px 0 5px 0",
                                    }}
                                  >
                                    {item.hn.padStart(6, "0")}
                                  </TableCell>
                                  <TableCell style={{ fontSize: "12px", maxWidth: "90px", padding: "0 0 0 5px" }}>
                                    {item.fname} {item.lname}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontSize: "12px",
                                      maxWidth: "20px",
                                      maxHeight: "30px",
                                      padding: "0 0 0 0",
                                    }}
                                  >
                                    {item.role}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontSize: "12px",
                                      padding: "2px 2px 2px 2px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{
                                        fontSize: "10px",
                                        padding: "0px 0px 0px 0px",
                                        width: "0px",
                                        display: "contents",
                                      }}
                                      onClick={() => {
                                        return selectpatient(item.TN, item.prefix, item.fname, item.lname);
                                      }}
                                    >
                                      <Send color="secondary" size="small" style={{ width: 15 }} />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Paper>
                <Paper style={{ marginTop: 10 }}>
                  <Grid item xs={4} sm={4}>
                    <CssBaseline />
                    <Container maxWidth="sm" sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        ลงนัดคนไข้
                      </Typography>
                      <Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4}>
                            <TextField id="TN" label="TN" variant="standard" placeholder="กรุณากรอก TN" value={`${selecttn}`} required />
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <TextField id="fullname" label="ชื่อสกุล" variant="standard" placeholder="กรุณากรอกชื่อ-สกุล" value={`${selectname}`} required />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <TextField id="date" label="วันที่" variant="standard" placeholder="วันที่" value={`${selectdate}`} required />
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <TextField id="เวลา" label="เวลา" value={timeString} variant="standard" required />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">เจ้าหน้าที่</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={provider}
                                onChange={(e) => {
                                  setProvider(e.target.value);
                                }}
                                label="เจ้าหน้าที่"
                              >
                                {/* <MenuItem value="ฟาตีเม๊าะ">ฟาตีเม๊าะ</MenuItem> */}
                                <MenuItem value="วันอารีนา">วันอารีนา</MenuItem>
                                <MenuItem value="นูรไอดา">นูรไอดา</MenuItem>
                                <MenuItem value="สีตีฟาตีเมาะ">สีตีฟาตีเมาะ</MenuItem>
                                <MenuItem value="ซัลมา">ซัลมา</MenuItem>
                                <MenuItem value="ฟาอีซา">ฟาอีซา</MenuItem>
                                <MenuItem value="หญิง">หญิง</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} sm={8}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">หัตถการ</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={service}
                                onChange={(e) => {
                                  setService(e.target.value);
                                }}
                                label="service"
                              >
                                <MenuItem value="นวด">นวด</MenuItem>
                                <MenuItem value="ประคบ">ประคบ</MenuItem>
                                <MenuItem value="นวดและประคบ">นวดและประคบ</MenuItem>
                                <MenuItem value="นวดและอบสมุนไพร">นวดและอบสมุนไพร</MenuItem>
                                <MenuItem value="ประคบและอบสมุนไพร">ประคบและอบสมุนไพร</MenuItem>
                                <MenuItem value="นวด ประคบและอบสมุนไพร">นวด ประคบและอบสมุนไพร</MenuItem>
                                <MenuItem value="บริบาลมารดาหลังคลอด">บริบาลมารดาหลังคลอด</MenuItem>
                                <MenuItem value="นวดเท้าเพื่อสุขภาพ">นวดเท้าเพื่อสุขภาพ</MenuItem>
                                <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">เจ้าหน้าที่ไม่พร้อมให้บริการ</MenuItem>
                                <MenuItem value="วันหยุดราชการหรืออื่นๆ">วันหยุดราชการหรืออื่นๆ</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={8}>
                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => handleSubmit(appUrl, appId, timeString, timeId)}>
                              นัดหมาย
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button fullWidth variant="contained" color="error" onClick={closemadal}>
                              ยกเลิก
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Container>
                  </Grid>
                </Paper>
              </Box>
            </Modal>
          </tbody>
        </table>
      </div>
      <Wait
        redata91={reData91}
        redata92={reData92}
        redata93={reData93}
        redata94={reData94}
        redata95={reData95}
        redata96={reData96}
        redata101={reData101}
        redata102={reData102}
        redata103={reData103}
        redata104={reData104}
        redata105={reData105}
        redata106={reData106}
        redata111={reData111}
        redata112={reData112}
        redata113={reData113}
        redata114={reData114}
        redata115={reData115}
        redata116={reData116}
        redata131={reData131}
        redata132={reData132}
        redata133={reData133}
        redata134={reData134}
        redata135={reData135}
        redata136={reData136}
        redata141={reData141}
        redata142={reData142}
        redata143={reData143}
        redata144={reData144}
        redata145={reData145}
        redata146={reData146}
      />
      <Note date={startDate} />
      <History
        redata91={reData91}
        redata92={reData92}
        redata93={reData93}
        redata94={reData94}
        redata95={reData95}
        redata96={reData96}
        redata101={reData101}
        redata102={reData102}
        redata103={reData103}
        redata104={reData104}
        redata105={reData105}
        redata106={reData106}
        redata111={reData111}
        redata112={reData112}
        redata113={reData113}
        redata114={reData114}
        redata115={reData115}
        redata116={reData116}
        redata131={reData131}
        redata132={reData132}
        redata133={reData133}
        redata134={reData134}
        redata135={reData135}
        redata136={reData136}
        redata141={reData141}
        redata142={reData142}
        redata143={reData143}
        redata144={reData144}
        redata145={reData145}
        redata146={reData146}
      />
    </div>
  );
}
