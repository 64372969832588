import axios from "axios";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import * as React from "react";
import Navback from "./Navbarback";
import Footer from "../fronthome/footer/footer";
import { Box } from "@mui/system";
import { Button, CssBaseline, Container, Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Modal from "@mui/material/Modal";
import TableContainer from "@mui/material/TableContainer";
import { TextField } from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import Reportprice from "./Reportprice";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const Report = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const REPORTURL =
    "https://script.google.com/macros/s/AKfycbwgHLIGCh4JMpgUGQzVJdrwl8G0wBmrexdHXTZSVJbfAbQvzPPHkfnTBKlOYARSvPWnag/exec?action=getUsers";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (
          response.data.status === "OK" &&
          (role === "admin" || role === "root")
        ) {
          // alert('login success')
        } else if (
          response.data.status === "OK" &&
          (role !== "admin" || role !== "root")
        ) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  const [budget, setBudget] = useState([]);
  const [reportIncome, setReportIncome] = useState(0);
  const [reportExpense, setReportExpense] = useState(0);
  const [reportAllprice, setReportAllprice] = useState(0);


  const getbudget = () => {
    axios.get(`${URL}/budget`).then((res) => {
      setBudget(res.data.sort((a, b) => b.ID - a.ID));
    });
  };

  useEffect(() => {
    const allprice = budget.map(item=>item.price)
    const income = allprice.filter(price=>price>=0).reduce((totalincome,price)=>totalincome+=price,0)
    const expense = allprice.filter(price=>price<0).reduce((totalexpense,price)=>totalexpense+=price,0)
    setReportIncome(income)
    setReportExpense(expense)
    setReportAllprice(income+expense)
  }, [budget,reportIncome,reportExpense,reportAllprice]);

  const [reportprice, setReportprice] = useState([]);

  const [passlist10, setPasslist10] = useState([]);
  const [datelist10, setDatelist10] = useState([]);

  const [passlist11, setPasslist11] = useState([]);
  const [datelist11, setDatelist11] = useState([]);

  const [passlist12, setPasslist12] = useState([]);
  const [datelist12, setDatelist12] = useState([]);

  const [passlist1, setPasslist1] = useState([]);
  const [datelist1, setDatelist1] = useState([]);

  const [passlist2, setPasslist2] = useState([]);
  const [datelist2, setDatelist2] = useState([]);

  const [passlist3, setPasslist3] = useState([]);
  const [datelist3, setDatelist3] = useState([]);

  const [passlist4, setPasslist4] = useState([]);
  const [datelist4, setDatelist4] = useState([]);

  const [passlist5, setPasslist5] = useState([]);
  const [datelist5, setDatelist5] = useState([]);

  const [passlist6, setPasslist6] = useState([]);
  const [datelist6, setDatelist6] = useState([]);

  const [passlist7, setPasslist7] = useState([]);
  const [datelist7, setDatelist7] = useState([]);

  const [passlist8, setPasslist8] = useState([]);
  const [datelist8, setDatelist8] = useState([]);

  const [passlist9, setPasslist9] = useState([]);
  const [datelist9, setDatelist9] = useState([]);

  const [passlists10, setPasslists10] = useState([]);
  const [datelists10, setDatelists10] = useState([]);

  const [passlists11, setPasslists11] = useState([]);
  const [datelists11, setDatelists11] = useState([]);

  const [passlists12, setPasslists12] = useState([]);
  const [datelists12, setDatelists12] = useState([]);

  const [passlists1, setPasslists1] = useState([]);
  const [datelists1, setDatelists1] = useState([]);

  const [passlists2, setPasslists2] = useState([]);
  const [datelists2, setDatelists2] = useState([]);

  const [passlists3, setPasslists3] = useState([]);
  const [datelists3, setDatelists3] = useState([]);

  const [passlists4, setPasslists4] = useState([]);
  const [datelists4, setDatelists4] = useState([]);

  const [passlists5, setPasslists5] = useState([]);
  const [datelists5, setDatelists5] = useState([]);

  const [passlists6, setPasslists6] = useState([]);
  const [datelists6, setDatelists6] = useState([]);

  const [passlists7, setPasslists7] = useState([]);
  const [datelists7, setDatelists7] = useState([]);

  const [passlists8, setPasslists8] = useState([]);
  const [datelists8, setDatelists8] = useState([]);

  const [passlists9, setPasslists9] = useState([]);
  const [datelists9, setDatelists9] = useState([]);

  const getreport = () => {
    axios.get(`${REPORTURL}`).then((res) => {
      setReportprice(res.data);
    });
  };

  const getpasslist = () => {
    if (new Date().getMonth() < 9) {
      const yearone = (new Date().getFullYear() + 541).toString();
      const yeartwo = (new Date().getFullYear() + 542).toString();
      const yeartree = (new Date().getFullYear() + 543).toString();
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist10(
          res.data.filter((e) => e.month === "ตุลาคม" && e.year === yearone)
        );
        setDatelist10(["ตุลาคม", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist11(
          res.data.filter((e) => e.month === "พฤศจิกายน" && e.year === yearone)
        );
        setDatelist11(["พฤศจิกายน", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist12(
          res.data.filter((e) => e.month === "ธันวาคม" && e.year === yearone)
        );
        setDatelist12(["ธันวาคม", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist1(
          res.data.filter((e) => e.month === "มกราคม" && e.year === yeartwo)
        );
        setDatelist1(["มกราคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist2(
          res.data.filter((e) => e.month === "กุมภาพันธ์" && e.year === yeartwo)
        );
        setDatelist2(["กุมภาพันธ์", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist3(
          res.data.filter((e) => e.month === "มีนาคม" && e.year === yeartwo)
        );
        setDatelist3(["มีนาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist4(
          res.data.filter((e) => e.month === "เมษายน" && e.year === yeartwo)
        );
        setDatelist4(["เมษายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist5(
          res.data.filter((e) => e.month === "พฤษภาคม" && e.year === yeartwo)
        );
        setDatelist5(["พฤษภาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist6(
          res.data.filter((e) => e.month === "มิถุนายน" && e.year === yeartwo)
        );
        setDatelist6(["มิถุนายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist7(
          res.data.filter((e) => e.month === "กรกฎาคม" && e.year === yeartwo)
        );
        setDatelist7(["กรกฎาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist8(
          res.data.filter((e) => e.month === "สิงหาคม" && e.year === yeartwo)
        );
        setDatelist8(["สิงหาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist9(
          res.data.filter((e) => e.month === "กันยายน" && e.year === yeartwo)
        );
        setDatelist9(["กันยายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists10(
          res.data.filter((e) => e.month === "ตุลาคม" && e.year === yeartwo)
        );
        setDatelists10(["ตุลาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists11(
          res.data.filter((e) => e.month === "พฤศจิกายน" && e.year === yeartwo)
        );
        setDatelists11(["พฤศจิกายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists12(
          res.data.filter((e) => e.month === "ธันวาคม" && e.year === yeartwo)
        );
        setDatelists12(["ธันวาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists1(
          res.data.filter((e) => e.month === "มกราคม" && e.year === yeartree)
        );
        setDatelists1(["มกราคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists2(
          res.data.filter(
            (e) => e.month === "กุมภาพันธ์" && e.year === yeartree
          )
        );
        setDatelists2(["กุมภาพันธ์", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists3(
          res.data.filter((e) => e.month === "มีนาคม" && e.year === yeartree)
        );
        setDatelists3(["มีนาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists4(
          res.data.filter((e) => e.month === "เมษายน" && e.year === yeartree)
        );
        setDatelists4(["เมษายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists5(
          res.data.filter((e) => e.month === "พฤษภาคม" && e.year === yeartree)
        );
        setDatelists5(["พฤษภาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists6(
          res.data.filter((e) => e.month === "มิถุนายน" && e.year === yeartree)
        );
        setDatelists6(["มิถุนายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists7(
          res.data.filter((e) => e.month === "กรกฎาคม" && e.year === yeartree)
        );
        setDatelists7(["กรกฎาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists8(
          res.data.filter((e) => e.month === "สิงหาคม" && e.year === yeartree)
        );
        setDatelists8(["สิงหาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists9(
          res.data.filter((e) => e.month === "กันยายน" && e.year === yeartree)
        );
        setDatelists9(["กันยายน", yeartwo]);
      });
      return;
    } else {
      const yearone = (new Date().getFullYear() + 542).toString();
      const yeartwo = (new Date().getFullYear() + 543).toString();
      const yeartree = (new Date().getFullYear() + 544).toString();
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist10(
          res.data.filter((e) => e.month === "ตุลาคม" && e.year === yearone)
        );
        setDatelist10(["ตุลาคม", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist11(
          res.data.filter((e) => e.month === "พฤศจิกายน" && e.year === yearone)
        );
        setDatelist11(["พฤศจิกายน", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist12(
          res.data.filter((e) => e.month === "ธันวาคม" && e.year === yearone)
        );
        setDatelist12(["ธันวาคม", yearone]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist1(
          res.data.filter((e) => e.month === "มกราคม" && e.year === yeartwo)
        );
        setDatelist1(["มกราคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist2(
          res.data.filter((e) => e.month === "กุมภาพันธ์" && e.year === yeartwo)
        );
        setDatelist2(["กุมภาพันธ์", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist3(
          res.data.filter((e) => e.month === "มีนาคม" && e.year === yeartwo)
        );
        setDatelist3(["มีนาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist4(
          res.data.filter((e) => e.month === "เมษายน" && e.year === yeartwo)
        );
        setDatelist4(["เมษายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist5(
          res.data.filter((e) => e.month === "พฤษภาคม" && e.year === yeartwo)
        );
        setDatelist5(["พฤษภาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist6(
          res.data.filter((e) => e.month === "มิถุนายน" && e.year === yeartwo)
        );
        setDatelist6(["มิถุนายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist7(
          res.data.filter((e) => e.month === "กรกฎาคม" && e.year === yeartwo)
        );
        setDatelist7(["กรกฎาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist8(
          res.data.filter((e) => e.month === "สิงหาคม" && e.year === yeartwo)
        );
        setDatelist8(["สิงหาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslist9(
          res.data.filter((e) => e.month === "กันยายน" && e.year === yeartwo)
        );
        setDatelist9(["กันยายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists10(
          res.data.filter((e) => e.month === "ตุลาคม" && e.year === yeartwo)
        );
        setDatelists10(["ตุลาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists11(
          res.data.filter((e) => e.month === "พฤศจิกายน" && e.year === yeartwo)
        );
        setDatelists11(["พฤศจิกายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists12(
          res.data.filter((e) => e.month === "ธันวาคม" && e.year === yeartwo)
        );
        setDatelists12(["ธันวาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists1(
          res.data.filter((e) => e.month === "มกราคม" && e.year === yeartree)
        );
        setDatelists1(["มกราคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists2(
          res.data.filter(
            (e) => e.month === "กุมภาพันธ์" && e.year === yeartree
          )
        );
        setDatelists2(["กุมภาพันธ์", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists3(
          res.data.filter((e) => e.month === "มีนาคม" && e.year === yeartree)
        );
        setDatelists3(["มีนาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists4(
          res.data.filter((e) => e.month === "เมษายน" && e.year === yeartree)
        );
        setDatelists4(["เมษายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists5(
          res.data.filter((e) => e.month === "พฤษภาคม" && e.year === yeartree)
        );
        setDatelists5(["พฤษภาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists6(
          res.data.filter((e) => e.month === "มิถุนายน" && e.year === yeartree)
        );
        setDatelists6(["มิถุนายน", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists7(
          res.data.filter((e) => e.month === "กรกฎาคม" && e.year === yeartree)
        );
        setDatelists7(["กรกฎาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists8(
          res.data.filter((e) => e.month === "สิงหาคม" && e.year === yeartree)
        );
        setDatelists8(["สิงหาคม", yeartwo]);
      });
      axios.get(`${URL}/passlist`).then((res) => {
        setPasslists9(
          res.data.filter((e) => e.month === "กันยายน" && e.year === yeartree)
        );
        setDatelists9(["กันยายน", yeartwo]);
      });
      return;
    }
  };

  const [openaddbudget, setAddbudget] = useState(false);

  const handleOpenaddbudget = () => setAddbudget(true);
  const handleCloseaddbudget = () => setAddbudget(false);

  const [openaddexpenses, setAddexpenses] = useState(false);

  const handleOpenaddexpenses = () => setAddexpenses(true);
  const handleCloseaddexpenses = () => setAddexpenses(false);

  useEffect(() => {
    setDate(new Date().toLocaleDateString("th-TH"));
    getbudget();
    getpasslist();
    getreport();
  }, [URL]);

  const [search, setSearch] = useState(reportprice);

  const testtt = (e) => {
    return setSearch(e.target.value);
  };

  function getNum(val) {
    if (isNaN(val)) {
      return 0;
    }
    return val;
  }

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState(new Date().toLocaleDateString("th-TH"));

  const submitform = (event) => {
    event.preventDefault();
    if(price>0){
      event.preventDefault();
      axios
      .post(`${URL}/budget`, {
        date: date,
        title: title,
        price: price,
      })
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "เพิ่มรายการเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            getbudget();
            handleCloseaddbudget();
            return;
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "ผิดพลาด",
            text: "ไม่สามารถเพิ่มรายการได้",
            showConfirmButton: false,
            timer: 2500,
          }).then((result) => {
            getbudget();
            handleCloseaddbudget();
            return;
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    }
    else{
      event.preventDefault();
      axios
      .post(`${URL}/budget`, {
        date: date,
        title: title,
        price: price.slice(1),
      })
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "เพิ่มรายการเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            getbudget();
            handleCloseaddbudget();
            return;
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "ผิดพลาด",
            text: "ไม่สามารถเพิ่มรายการได้",
            showConfirmButton: false,
            timer: 2500,
          }).then((result) => {
            getbudget();
            handleCloseaddbudget();
            return;
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    }
  };

  const submitexpenses = (event) => {
    event.preventDefault();
    if (price <= 0) {
      event.preventDefault();
      axios
        .post(`${URL}/budget`, {
          date: date,
          title: title,
          price: price,
        })
        .then(function (response) {
          if (response.data["status"] === "OK") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "เพิ่มรายการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              getbudget();
              handleCloseaddexpenses();
              return;
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ผิดพลาด",
              text: "ไม่สามารถเพิ่มรายการได้",
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              getbudget();
              handleCloseaddexpenses();
              return;
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    } else {
      event.preventDefault();
      event.preventDefault();
      axios
        .post(`${URL}/budget`, {
          date: date,
          title: title,
          price: `-${price}`,
        })
        .then(function (response) {
          if (response.data["status"] === "OK") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "เพิ่มรายการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              getbudget();
              handleCloseaddexpenses();
              return;
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "ผิดพลาด",
              text: "ไม่สามารถเพิ่มรายการได้",
              showConfirmButton: false,
              timer: 2500,
            }).then((result) => {
              getbudget();
              handleCloseaddexpenses();
              return;
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  };



  return (
    <div>
      <Navback />
      <CssBaseline />
      <Container maxWidth="xl">
        <Box>
          <Paper style={{ marginBottom: "30px", marginTop: "100px" }}>
            <h1 style={{ textAlign: "center", color: "#8a2be2"}}>สรุปยอดรายรับ - รายจ่าย</h1>
            <Grid container spacing={3} style={{ marginBottom: "15px", marginTop:"15px" }}>
            <Grid item xs={4}>
              <h2 style={{ textAlign: "center", color: "#397D54"}}>รายรับรวม : {reportIncome} </h2>
            </Grid>
            <Grid item xs={4}>
              <h2 style={{ textAlign: "center", color: "#FF202B"}}>รายจ่ายรวม : {reportExpense} </h2>
            </Grid>
            <Grid item xs={4}>
              <h2 style={{ textAlign: "center", color: "#2a36b1"}}>รายได้สุทธิ : {reportAllprice} </h2>
            </Grid>
          </Grid>
            
            <div style={{ textAlign: "right", marginBottom: "5px" }}>
              <Button
                style={{ marginRight: "5px" }}
                variant="contained"
                color="success"
                size="small"
                startIcon={<AddCircle />}
                onClick={handleOpenaddbudget}
              >
                เพิ่มรายรับ
              </Button>
              <Modal
                open={openaddbudget}
                onClose={handleCloseaddbudget}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={stylee}>
                  <form onSubmit={submitform}>
                    <Grid margim ={1}>
                      <h1
                        style={{
                          textAlign: "center",
                          borderRight:"10px solid #01A6BA",
                          borderLeft:"10px solid #01A6BA",
                          marginTop:"20px",
                          marginBottom:"40px",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        เพิ่มรายรับ{" "}
                      </h1>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="tels"
                            label="รายการ"
                            ariant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setTitle(event.target.value);
                            }}
                            value={title}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="tels"
                            label="จำนวนเงิน"
                            type="number"
                            placeholder={"ไม่ต้องใส่เครื่องหมายบวก(+)"}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setPrice(event.target.value);
                            }}
                            value={price}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                          >
                            ยืนยัน
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            onClick={handleCloseaddbudget}
                            variant="contained"
                            color="error"
                            size="large"
                            fullWidth
                          >
                            ยกเลิก
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Modal>
              <Button
                variant="contained"
                color="error"
                size="small"
                startIcon={<RemoveCircle />}
                onClick={handleOpenaddexpenses}
              >
                เพิ่มรายจ่าย
              </Button>
              <Modal
                open={openaddexpenses}
                onClose={handleCloseaddexpenses}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={stylee}>
                  <form onSubmit={submitexpenses}>
                    <Grid margin={1}>
                      <h1
                        style={{
                          textAlign: "center",
                          marginTop:"20px",
                          marginBottom:"40px",
                          borderRadius: "5px",
                          borderRight:"10px solid #e79129",
                          borderLeft:"10px solid #e79129"
                        }}
                      >
                        {" "}
                        เพิ่มรายจ่าย{" "}
                      </h1>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="tels"
                            label="รายการ"
                            ariant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setTitle(event.target.value);
                            }}
                            value={title}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="tels"
                            label="จำนวนเงิน"
                            type="number"
                            placeholder={"ไม่ต้องใส่เครื่องหมายลบ(-)"}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) => {
                              setPrice(event.target.value);
                            }}
                            value={price}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                          >
                            ยืนยัน
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Button
                            onClick={handleCloseaddexpenses}
                            variant="contained"
                            color="error"
                            size="large"
                            fullWidth
                          >
                            ยกเลิก
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Modal>
            </div>
            <TableContainer
              sx={{ height: "600px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ marginBottom: "10px" }}
              >
                <thead style={{ fontSize: "20px",fontWeight:"bolder",backgroundColor:"#51C2D5",height:"40px" }}>
                  <tr>
                    <th style={{ width:"25%" }}>วัน/เดือน/ปี</th>
                    <th style={{ width:"50%" }}>รายการ</th>
                    <th style={{ width:"25%"}}>ยอดรายรับ-รายจ่าย</th>
                  </tr>
                </thead>
                <tbody>
                  {budget.map((item) => {
                    return (
                      <tr key={item.ID}>
                        <td align="left">{item.date}</td>
                        <td style={{ textAlign: "left", }}>{item.title}</td>
                        <Reportprice items = {item.price}/>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper style={{ marginBottom: "30px", marginTop: "100px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
                marginBottom: "10px",
              }}
            >
              <h1>สรุปยอดการเข้ารับบริการ</h1>
            </div>
            <TableContainer
              sx={{ height: "600px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ marginBottom: "10px" }}
              >
                <thead className="headlist">
                  <tr>
                    <th rowSpan="2" colSpan="2">
                      รายการ
                    </th>
                    <th colSpan="2">นัดหมายทั้งหมด</th>
                    <th colSpan="2">มาตามนัดหมาย</th>
                    <th colSpan="2">ผิดนัดหมาย</th>
                  </tr>
                  <tr className="trlist">
                    <th>จำนวนครั้ง</th>
                    <th>ร้อยละ</th>
                    <th>จำนวนครั้ง</th>
                    <th>ร้อยละ</th>
                    <th>จำนวนครั้ง</th>
                    <th>ร้อยละ</th>
                  </tr>
                </thead>
                <tbody className="passlist">
                  <tr>
                    <td>{datelist10[0]}</td>
                    <td>{datelist10[1]}</td>
                    <td>{passlist10.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist10.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist10.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist10.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist10.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist10.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist10.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist11[0]}</td>
                    <td>{datelist11[1]}</td>
                    <td>{passlist11.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist11.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist11.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist11.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist11.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist11.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist11.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist12[0]}</td>
                    <td>{datelist12[1]}</td>
                    <td>{passlist12.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist12.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist12.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist12.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist12.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist12.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist12.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist1[0]}</td>
                    <td>{datelist1[1]}</td>
                    <td>{passlist1.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist1.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist1.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist1.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist1.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist1.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist1.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist2[0]}</td>
                    <td>{datelist2[1]}</td>
                    <td>{passlist2.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist2.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist2.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist2.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist2.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist2.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist2.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist3[0]}</td>
                    <td>{datelist3[1]}</td>
                    <td>{passlist3.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist3.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist3.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist3.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist3.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist3.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist3.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist4[0]}</td>
                    <td>{datelist4[1]}</td>
                    <td>{passlist4.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist4.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist4.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist4.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist4.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist4.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist4.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist5[0]}</td>
                    <td>{datelist5[1]}</td>
                    <td>{passlist5.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist5.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist5.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist5.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist5.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist5.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist5.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist6[0]}</td>
                    <td>{datelist6[1]}</td>
                    <td>{passlist6.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist6.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist6.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist6.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist6.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist6.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist6.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist7[0]}</td>
                    <td>{datelist7[1]}</td>
                    <td>{passlist7.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist7.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist7.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist7.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist7.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist7.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist7.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist8[0]}</td>
                    <td>{datelist8[1]}</td>
                    <td>{passlist8.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist8.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist8.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist8.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist8.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist8.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist8.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelist9[0]}</td>
                    <td>{datelist9[1]}</td>
                    <td>{passlist9.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlist9.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist9.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlist9.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlist9.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlist9.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlist9.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists10[0]}</td>
                    <td>{datelists10[1]}</td>
                    <td>{passlists10.length}</td>
                    <td>100.00</td>
                    <td>
                      {
                        passlists10.filter((e) => e.status === "มาตามนัด")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists10.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists10.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists10.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists10.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists10.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists11[0]}</td>
                    <td>{datelists11[1]}</td>
                    <td>{passlists11.length}</td>
                    <td>100.00</td>
                    <td>
                      {
                        passlists11.filter((e) => e.status === "มาตามนัด")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists11.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists11.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists11.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists11.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists11.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists12[0]}</td>
                    <td>{datelists12[1]}</td>
                    <td>{passlists12.length}</td>
                    <td>100.00</td>
                    <td>
                      {
                        passlists12.filter((e) => e.status === "มาตามนัด")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists12.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists12.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists12.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists12.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists12.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists1[0]}</td>
                    <td>{datelists1[1]}</td>
                    <td>{passlists1.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists1.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists1.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists1.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists1.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists1.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists1.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists2[0]}</td>
                    <td>{datelists2[1]}</td>
                    <td>{passlists2.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists2.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists2.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists2.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists2.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists2.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists2.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists3[0]}</td>
                    <td>{datelists3[1]}</td>
                    <td>{passlists3.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists3.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists3.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists3.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists3.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists3.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists3.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists4[0]}</td>
                    <td>{datelists4[1]}</td>
                    <td>{passlists4.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists4.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists4.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists4.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists4.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists4.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists4.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists5[0]}</td>
                    <td>{datelists5[1]}</td>
                    <td>{passlists5.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists5.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists5.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists5.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists5.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists5.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists5.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists6[0]}</td>
                    <td>{datelists6[1]}</td>
                    <td>{passlists6.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists6.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists6.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists6.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists6.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists6.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists6.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists7[0]}</td>
                    <td>{datelists7[1]}</td>
                    <td>{passlists7.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists7.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists7.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists7.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists7.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists7.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists7.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists8[0]}</td>
                    <td>{datelists8[1]}</td>
                    <td>{passlists8.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists8.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists8.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists8.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists8.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists8.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists8.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{datelists9[0]}</td>
                    <td>{datelists9[1]}</td>
                    <td>{passlists9.length}</td>
                    <td>100.00</td>
                    <td>
                      {passlists9.filter((e) => e.status === "มาตามนัด").length}
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists9.filter((e) => e.status === "มาตามนัด")
                            .length *
                            100) /
                          passlists9.length
                        ).toFixed(2)
                      )}
                    </td>
                    <td>
                      {
                        passlists9.filter((e) => e.status === "ผิดนัดหมาย")
                          .length
                      }
                    </td>
                    <td>
                      {getNum(
                        (
                          (passlists9.filter((e) => e.status === "ผิดนัดหมาย")
                            .length *
                            100) /
                          passlists9.length
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper style={{ marginBottom: "100px", marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <h1>สรุปยอดรายได้รวม</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                marginBottom: "10px",
                marginRight: "10px",
              }}
            >
              <TextField
                id="fullWidth"
                label="ค้นหา..."
                align="center"
                variant="outlined"
                onChange={testtt}
                size="small"
                style={{ width: "20%" }}
              />
            </div>
            <TableContainer
              sx={{ height: "600px" }}
              style={{ marginBottom: "10px" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ marginBottom: "10px" }}
              >
                <thead className="headlist">
                  <tr>
                    <th colSpan="2">รายการ</th>
                    <th rowSpan="2">จำนวน (ครั้ง)</th>
                    <th rowSpan="2">รายรับรวม (ไม่รวมสิทธิประกันสังคม)</th>
                    <th rowSpan="2">รายจ่าย (เงินเดือนเจ้าหน้าที่)</th>
                    <th rowSpan="2">รายจ่าย (เงินล่วงเวลาเจ้าหน้าที่)</th>
                    <th rowSpan="2">รายได้รวม</th>
                  </tr>
                  <tr className="trlist">
                    <th>เดือน</th>
                    <th>ปี</th>
                  </tr>
                </thead>
                <tbody className="passlist">
                  {reportprice
                    .filter((e) => {
                      return (
                        e.date.includes(search) ||
                        e.month.includes(search) ||
                        e.year.toString().includes(search)
                      );
                    })
                    .map((item) => {
                      return (
                        <tr key={uuidv4()}>
                          <td>{item.month}</td>
                          <td>{item.year}</td>
                          <td>{item.numbers}</td>
                          <td>{item.revenue.toFixed(2)}</td>
                          <td>{item.salary.toFixed(2)}</td>
                          <td>{item.parttime.toFixed(2)}</td>
                          <td>{item.income.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default Report;
