import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Button, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Searchrole from "./Searchrole";

const Usercreate = (props) => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK" && (role === "admin" || role === "root")) {
          // alert('login success')
        } else if (response.data.status === "OK" && (role !== "admin" || role !== "root")) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            localStorage.clear();
            window.location.href = "/login";
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [URL]);

  //adusers
  const [prefix, setPrefix] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [role, setRole] = useState("");
  const [tel, setTel] = useState("");
  const [hn, setHN] = useState("");
  const [cid, setCid] = useState("");

  const prefixx = (event) => {
    setPrefix(event.target.value);
  };

  const submitform = (event) => {
    event.preventDefault();
    axios
      .post(`${URL}/register`, {
        prefix: prefix,
        fname: fname,
        lname: lname,
        role: role,
        tel: tel,
        password: tel,
        hn: hn,
        cid: cid
      })
      .then(function (response) {
        if (response.data["status"] === "OK") {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "เพิ่มสมาชิกเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            props.getuser();
            return;
          });
        } else if (response.data.message.sqlState === "23000") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "ผิดพลาด",
            text: "เบอร์โทรศัพท์หรือเลขสมาชิกมีผู้อื่นใช้แล้ว",
            showConfirmButton: false,
            timer: 2500,
          }).then((result) => {
            props.getuser();
            props.close(true);
            return;
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const roleset = (pcode,pttype) =>{
    if((pcode==="A2")){
      if((pttype==="21")||(pttype==="25")){
        setRole("A2")
        return
      }
      setRole("user")
      return
    }
    else if((pcode==="UC")||(pcode==="UA")||(pcode==="UC")||(pcode==="UB")||(pcode==="AA")||(pcode==="AB")||(pcode==="AC")||(pcode==="AD")||(pcode==="AE")||(pcode==="AF")||(pcode==="AG")||(pcode==="AJ")||(pcode==="K")){
      setRole("UC")
      return
    }
    else if((pcode==="A7")){
      setRole("A7")
      return
    }
    setRole("user")
    return
  }

  const setdata = (data) => {
    setPrefix(data.pname)
    setFname(data.fname)
    setLname(data.lname)
    setCid(data.cid)
    setHN(Number(data.hn).toString())
    roleset(data.pcode,data.pttype)
    checktel(data.hometel)
  }

  const checktel =(tel)=>{
    const teldata = tel.replace("-", "")
    if(teldata.length===0){
      setTel("")
      return
    }
    else if(teldata.length===10){
      setTel(teldata)
      return
    }
    else{
      setTel("")
      return
    }
  }

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Searchrole setdata={setdata}/>
        {/* <div>
          <TextField
            id={uuidv4()}
            label="เลขบัตรประชาชน"
            size="small"
            fullWidth
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onChange={(event) => {
              setCid(event.target.value);
            }}
            value={cid}
          />
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              gethosxp(cid);
            }}
            fullWidth
          >
            ค้นหารายชื่อ
          </Button>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "30%",
                      fontWeight: "bolder",
                      height:'100px'
                    }}
                  >
                    สิทธิ์การรักษา
                  </th>
                  <th>{rolename}</th>
                </tr>
                <tr>
                  <th
                    style={{
                      width: "30%",
                      fontWeight: "bolder",
                      height:'50px'
                    }}
                  >
                    เบอร์ติดต่อ
                  </th>
                  <th>{roletel}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div> */}
        <Typography variant="h6" gutterBottom component="div">
          สมัครสมาชิก
        </Typography>
        <form onSubmit={submitform}>
          <Grid>
            <Grid container spacing={2}>
              <Grid item xs={3.5} sm={3.5}>
                <TextField label="คำนำหน้า" variant="outlined" fullWidth required onChange={prefixx} value={prefix} />
              </Grid>
              <Grid item xs={4.25} sm={4.25}>
                <TextField
                  label="ชื่อ"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(event) => {
                    setFname(event.target.value);
                  }}
                  value={fname}
                />
              </Grid>
              <Grid item xs={4.25} sm={4.25}>
                <TextField
                  label="นามสกุล"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(event) => {
                    setLname(event.target.value);
                  }}
                  value={lname}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  label="HN"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setHN(event.target.value);
                  }}
                  value={hn}
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="เลขที่บัตรประชาชน"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setCid(event.target.value);
                  }}
                  value={cid}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="demo-simple-select-standard-label">สิทธิ์</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="role"
                    onChange={(event) => {
                      setRole(event.target.value);
                    }}
                    value={role}
                  >
                    <MenuItem value="admin">ผู้ดูแลระบบ</MenuItem>
                    <MenuItem value="user">ผู้ใช้งานทั่วไป</MenuItem>
                    <MenuItem value="UC">บัตรทอง</MenuItem>
                    <MenuItem value="A2">ข้าราชการ</MenuItem>
                    <MenuItem value="A7">ประกันสังคม</MenuItem>
                    <MenuItem value="A1">ชำระเงิน</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="เบอร์โทรศัพท์"
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(event) => {
                    setTel(event.target.value);
                  }}
                  value={tel}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField type="password" label="รหัสผ่าน" variant="outlined" fullWidth required value={tel} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};
export default Usercreate;
