import "./Calendar.css";
import * as React from "react";
import { useEffect} from "react";
import Calendaruserslinefive from "./Calendaruserslinefive";
import { useNavigate } from "react-router-dom";
import { Box, Container, CssBaseline } from "@mui/material";
import axios from "axios";

const Calendarusersline = () => {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const TN = localStorage.getItem("TN");
    const role = localStorage.getItem("role");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          if (role === "admin" || role === "root") {
            navigate("/calendaradminline");
            return;
          } else {
            liff.init(
              { liffId: "1657187328-2wnqP3GZ" },
              () => {
                liff.getFriendship().then((data) => {
                  if (data.friendFlag) {
                    liff
                      .getProfile()
                      .then((profile) => {
                        axios
                          .put(`${URL}/uuidline/${TN}`, {
                            uuid: profile.userId,
                          })
                          .then(function (response) {
                            navigate("/calendargroupline");
                          })
                          .catch(function (error) {
                            console.log(error);
                          });
                      })
                      .catch((err) => console.error(err));
                    return;
                  } else {
                    window.location = "https://line.me/R/ti/p/@354pzfxs";
                  }
                });
              },
              (err) => console.error(err)
            );
            return;
          }
        } else {
          liff.init(
            { liffId: "1657187328-2wnqP3GZ" },
            () => {
              liff.getFriendship().then((data) => {
                if (data.friendFlag) {
                  localStorage.removeItem("fname");
                  localStorage.removeItem("lname");
                  localStorage.removeItem("TN");
                  localStorage.removeItem("role");
                  localStorage.removeItem("prefix");
                  localStorage.removeItem("uuid");
                  navigate("/calendarusersline");
                  return;
                } else {
                  window.location = "https://line.me/R/ti/p/@354pzfxs";
                }
              });
            },
            (err) => console.error(err)
          );
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, URL]);
  return (
    <div
      className="aboutpages"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Container>
        <Box>
          <Calendaruserslinefive />
        </Box>
      </Container>
    </div>
  );
};
export default Calendarusersline;