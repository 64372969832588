import "./Calendar.css";
import liff from "@line/liff";
import * as React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Paper";
import Webcalendar from "./WebCalendar";
import Searchhistory from "./Searchhistory";
import Webcalendarline from "./WebCalendarline";

const Calendarusersgrouplinefive = () => {
  const navigate = useNavigate();
  const move = () => {
    navigate("/logingroupline");
  };

  return (
    <Paper
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        paddingTop: "10px",
        border: "1px solid #e1932c",
      }}
    >
      <div style={{ textAlign: "right", marginRight: "10px", marginBottom: "10px" }}>
        <Button variant="contained" color="success" size="small" onClick={move} className="btnstatus" style={{ marginRight: "10px" }}>
          <p>เข้าสู่ระบบ</p>
        </Button>
      </div>
      <Webcalendarline />
      <div style={{ margin: "10px" }}>
        <Searchhistory />
      </div>
    </Paper>
  );
};
export default Calendarusersgrouplinefive;
